import React, { FC } from 'react';
import { ProfileDetail } from '../../../../../../schema/ProfileDetail/ProfileDetail';
import { Box, Card, Skeleton, Typography } from '@mui/joy';
import { useScreenSize } from '../../../../../../hooks/useScreenSize';

export interface EducationProps {
	profile: ProfileDetail | null;
}

const Education: FC<EducationProps> = ({ profile }) => {
	const { sizeIs } = useScreenSize();

	const yearFromUnix = (unix: number) => {
		const date = new Date(unix);
		return date.getFullYear();
	};

	if (!profile) {
		return (
			<Box>
				<Skeleton width={sizeIs('phone') ? '100%' : '320px'} height="220px" />
			</Box>
		);
	}
	return (
		<Box
			sx={{
				display: 'flex',
				flexFlow: 'column',
				gap: 1,
			}}
		>
			{profile.education.map((edu, index) => (
				<Card key={`edu_${index}`}>
					<Typography level="body-lg" sx={{ fontWeight: 'bold' }}>
						{edu.school}
					</Typography>
					<Typography>
						{edu.degree}, {edu.year}
					</Typography>
				</Card>
			))}
		</Box>
	);
};

export default Education;
