import {
	Box,
	Button,
	Card,
	FormControl,
	FormLabel,
	Input,
	Typography,
	useTheme,
} from '@mui/joy';
import React, { FC, ReactNode } from 'react';
import DividerText from '../../../components/layout/DividerText';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '../../../hooks/useScreenSize';

export interface LoginFormProvider {
	label: string;
	icon?: ReactNode;
	handler: () => void;
	loading: boolean;
}

export interface LoginFormProps {
	value: { email: string; password: string };
	onChange: (value: { email: string; password: string }) => void;
	email: {
		loading: boolean;
		handler: () => void;
	};
	providers?: LoginFormProvider[];
	ref?: React.RefObject<any>;
}

const LoginForm: FC<LoginFormProps> = ({
	value,
	onChange,
	email,
	providers = [],
	ref,
}) => {
	const { palette } = useTheme();
	const { sizeIs } = useScreenSize();
	const nav = useNavigate();

	return (
		<Card
			variant={sizeIs('phone') ? 'plain' : undefined}
			ref={ref}
			sx={{
				display: 'flex',
				flexFlow: 'column',
				background: sizeIs('phone') ? palette.background.body : undefined,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					p: 2,
				}}
			>
				<Typography level="h1">Login</Typography>
			</Box>
			{providers.length > 0 && (
				<Box>
					<DividerText>With</DividerText>
					<Box p={1.5} />
					<Box sx={{ display: 'flex', gap: 2, flexFlow: 'column' }}>
						{providers.map((provider, index) => (
							<Button
								key={`provider-${index}`}
								size="lg"
								fullWidth
								onClick={provider.handler}
								startDecorator={provider.icon}
								color="neutral"
								variant="soft"
								loading={provider.loading}
							>
								{provider.label}
							</Button>
						))}
					</Box>
					<Box p={1.5} />
					<DividerText>Or</DividerText>
					<Box p={1.5} />
				</Box>
			)}
			<form
				style={{
					flex: 1,
					display: 'flex',
					flexFlow: 'column',
				}}
			>
				<FormControl>
					<FormLabel>Email</FormLabel>
					<Input
						placeholder="Email"
						sx={{ minWidth: '340px' }}
						size="lg"
						value={value.email}
						onChange={(e) => {
							onChange({ ...value, email: e.target.value });
						}}
					/>
				</FormControl>
				<Box p={1} />
				<FormControl>
					<FormLabel>Password</FormLabel>
					<Input
						placeholder="Password"
						sx={{ minWidth: '340px' }}
						size="lg"
						type="password"
						value={value.password}
						onChange={(e) => {
							onChange({ ...value, password: e.target.value });
						}}
					/>
				</FormControl>
				<Box
					sx={{
						flex: 1,
						minHeight: '32px',
					}}
				/>
				<Button
					size="lg"
					fullWidth
					type="submit"
					onClick={email.handler}
					loading={email.loading}
				>
					Login
				</Button>
			</form>
			<Box p={0.25} />
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
				<Typography level="body-sm">Need an account?</Typography>
				<Button
					onClick={() => nav('/signup')}
					size="sm"
					color="neutral"
					variant="plain"
				>
					Sign up
				</Button>
			</Box>
		</Card>
	);
};

export default LoginForm;
