import React, { FC } from 'react';
import { schema } from '../../../../schema';
import { Box } from '@mui/joy';
import ProfileHeader from './components/ProfileHeader';
import ProfileSection from './components/ProfileSection';
import Education from './components/Education';
import Experience from './components/Experience';
import Bio from './components/Bio';

export interface ProfileDetailProps {
	uid: string;
	back?: { path: string; label: string };
}

const ProfileDetail: FC<ProfileDetailProps> = ({ uid, back }) => {
	const { data, error, loading } = schema.profileDetail.get({
		variables: { uid },
	});

	const profile = data?.ProfileDetail || null;

	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				flexFlow: 'column',
			}}
		>
			<ProfileHeader back={back} profile={profile} />
			<Box
				sx={{
					display: 'flex',
					flexFlow: 'column',
					gap: 4,
					p: 4,
					overflow: 'auto',
				}}
			>
				<ProfileSection label="Bio">
					<Bio profile={profile} />
				</ProfileSection>
				<ProfileSection label="Education">
					<Education profile={profile} />
				</ProfileSection>
				<ProfileSection label="Experience">
					<Experience profile={profile} />
				</ProfileSection>
				<Box p={2} />
			</Box>
		</Box>
	);
};

export default ProfileDetail;
