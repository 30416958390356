import React, { useState } from 'react';
import Page from '../../components/layout/Page';
import Centered from '../../components/layout/Centered';
import LoginForm from './LoginForm';
import { useAuthContext } from '../../providers/AuthProvider';
import { FaGoogle, FaMicrosoft } from 'react-icons/fa';
import { Box, Card, IconButton, Typography } from '@mui/joy';
import Collapse from '../../components/layout/Collapse';
import { MdClear } from 'react-icons/md';
import { useScreenSize } from '../../hooks/useScreenSize';

const Login: React.FC = () => {
	const { login } = useAuthContext();

	const { sizeIs } = useScreenSize();

	const [error, setError] = useState<Error | null>(null);

	const [state, setState] = useState({
		email: '',
		password: '',
	});

	const handleEmail = async () => {
		const res = await login.email.handler(state);

		if (res.ok) {
			return;
		} else {
			setError(res.error);
		}
	};

	const handleGoogle = async () => {
		const res = await login.google.handler();

		if (res.ok) {
			return;
		} else {
			setError(res.error);
		}
	};

	const handleMicrosoft = async () => {
		const res = await login.microsoft.handler();

		if (res.ok) {
			return;
		} else {
			setError(res.error);
		}
	};

	const getErrorMessage = () => {
		if (!error) return '';

		const options: Record<string, string> = {
			'invalid-email': 'The email or password is invalid.',
			'auth/missing-password': 'Please enter a password.',
			'auth/popup-closed-by-user': 'Please allow popups to signup.',
			'auth/email-already-in-use': 'This email is already in use.',
			'auth/invalid-login-credentials': 'The email or password is invalid.',
		};

		const match = Object.keys(options).find((key) =>
			error.message.includes(key)
		);

		return match ? options[match] : error.message;
	};

	const message = getErrorMessage();

	return (
		<Page>
			<Centered>
				{!sizeIs('phone') && <Box p={4} />}
				<LoginForm
					providers={[
						{
							label: 'Google',
							handler: handleGoogle,
							icon: <FaGoogle />,
							loading: login.google.loading,
						},
						{
							label: 'Micrososft',
							handler: handleMicrosoft,
							icon: <FaMicrosoft />,
							loading: login.microsoft.loading,
						},
					]}
					value={state}
					onChange={setState}
					email={{
						loading: login.email.loading,
						handler: handleEmail,
					}}
				/>
				<Box
					sx={{
						height: sizeIs('phone') ? undefined : '120px',
						paddingTop: sizeIs('phone') ? 0 : 2,
					}}
				>
					<Collapse open={Boolean(message)}>
						<Box
							sx={{
								p: sizeIs('phone') ? 2 : 0,
							}}
						>
							<Card color="warning" variant="outlined">
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 2,
										justifyContent: 'space-between',
									}}
								>
									<Typography>{message}</Typography>
									<IconButton
										onClick={() => {
											setError(null);
										}}
										size="sm"
									>
										<MdClear />
									</IconButton>
								</Box>
							</Card>
						</Box>
					</Collapse>
				</Box>
			</Centered>
		</Page>
	);
};

export default Login;
