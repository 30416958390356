import { gql } from '@apollo/client';
import { Identified } from '../Identified/Identified';
import { ProfileDetail } from '../ProfileDetail/ProfileDetail';
import { Response } from '../Response/Response';

export class Booking extends Identified {
	mentor!: ProfileDetail;
	mentee!: ProfileDetail;
	dateCreated!: number;
	start!: number;
	end!: number;
	note!: string;
	response!: Response | null;
}

export const BookingFragment = gql`
	fragment BookingFragment on Booking {
		_id
		mentor {
			...ProfileDetailFragment
		}
		mentee {
			...ProfileDetailFragment
		}
		dateCreated
		start
		end
		note
		response {
			...ResponseFragment
		}
	}
`;
