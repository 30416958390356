import {
	Avatar,
	Dropdown,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
} from '@mui/joy';
import { User } from 'firebase/auth';
import React, { FC } from 'react';
import { MdAccountCircle, MdExitToApp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export interface UserAvatarProps {
	children: User | null;
}

const UserAvatar: FC<UserAvatarProps> = ({ children: user }) => {
	const nav = useNavigate();

	return (
		<Dropdown>
			<MenuButton
				slots={{ root: IconButton }}
				slotProps={{
					root: {
						variant: 'plain',
						color: 'neutral',
						sx: {
							p: 0,
							borderRadius: '50%',
						},
					},
				}}
			>
				<Avatar
					variant="outlined"
					src={user?.photoURL || undefined}
					alt={user?.displayName || undefined}
				/>
			</MenuButton>
			<Menu placement="bottom-start">
				<MenuItem onClick={() => nav('/app/account')}>
					<MdAccountCircle />
					My Account
				</MenuItem>
				<MenuItem onClick={() => nav('/logout')}>
					<MdExitToApp />
					Logout
				</MenuItem>
			</Menu>
		</Dropdown>
	);
};

export default UserAvatar;
