import { extendTheme } from '@mui/joy';

export const theme = extendTheme({
	components: {
		JoyInput: {
			defaultProps: {
				color: 'neutral',
				variant: 'soft',
			},
		},
		JoySelect: {
			defaultProps: {
				color: 'neutral',
				variant: 'soft',
			},
		},
		JoyTextarea: {
			defaultProps: {
				color: 'neutral',
				variant: 'soft',
			},
		},
	},
	colorSchemes: {
		light: {
			palette: {
				primary: {
					'50': '#fef2f2',
					'100': '#fee2e2',
					'200': '#fecaca',
					'300': '#fca5a5',
					'400': '#f87171',
					'500': '#ef4444',
					'600': '#dc2626',
					'700': '#b91c1c',
					'800': '#991b1b',
					'900': '#7f1d1d',
				},
				danger: {
					'50': '#fff7ed',
					'100': '#ffedd5',
					'200': '#fed7aa',
					'300': '#fdba74',
					'400': '#fb923c',
					'500': '#f97316',
					'600': '#ea580c',
					'700': '#c2410c',
					'800': '#9a3412',
					'900': '#7c2d12',
				},
				warning: {
					'50': '#fefce8',
					'100': '#fef9c3',
					'200': '#fef08a',
					'300': '#fde047',
					'400': '#facc15',
					'500': '#eab308',
					'600': '#ca8a04',
					'700': '#a16207',
					'800': '#854d0e',
					'900': '#713f12',
				},
			},
		},
		dark: {
			palette: {
				primary: {
					'50': '#fef2f2',
					'100': '#fee2e2',
					'200': '#fecaca',
					'300': '#fca5a5',
					'400': '#f87171',
					'500': '#ef4444',
					'600': '#dc2626',
					'700': '#b91c1c',
					'800': '#991b1b',
					'900': '#7f1d1d',
				},
				danger: {
					'50': '#fff7ed',
					'100': '#ffedd5',
					'200': '#fed7aa',
					'300': '#fdba74',
					'400': '#fb923c',
					'500': '#f97316',
					'600': '#ea580c',
					'700': '#c2410c',
					'800': '#9a3412',
					'900': '#7c2d12',
				},
				warning: {
					'50': '#fefce8',
					'100': '#fef9c3',
					'200': '#fef08a',
					'300': '#fde047',
					'400': '#facc15',
					'500': '#eab308',
					'600': '#ca8a04',
					'700': '#a16207',
					'800': '#854d0e',
					'900': '#713f12',
				},
			},
		},
	},
});
