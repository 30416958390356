import { Input, InputProps } from '@mui/joy';
import React, { FC, useEffect, useState } from 'react';

export interface TriggerInputProps
	extends Omit<InputProps, 'value' | 'onChange'> {
	value: string;
	onChange: (value: string) => void;
	threshold?: number;
}

const TriggerInput: FC<TriggerInputProps> = ({
	value,
	onChange,
	threshold = 500,
	...rest
}) => {
	const [val, setVal] = useState(value);

	useEffect(() => {
		// debounce
		const timeout = setTimeout(() => {
			onChange(val);
		}, threshold);

		return () => clearTimeout(timeout);
	}, [val]);

	return (
		<Input
			value={val}
			onChange={(e) => {
				setVal(e.target.value);
			}}
			{...rest}
		/>
	);
};

export default TriggerInput;
