import { Box, Typography, useTheme } from '@mui/joy';
import React, { FC } from 'react';
import Collapse from '../../../../../../../../../Collapse';

export interface NavDrawerChipRenderProps {
	onClick?: () => void;
	isActive?: boolean;
	open?: boolean;
	icon: React.ReactNode;
	label: string;
}

const NavDrawerChipRender: FC<NavDrawerChipRenderProps> = ({
	onClick,
	isActive = false,
	open = true,
	icon,
	label,
}) => {
	const { palette } = useTheme();

	return (
		<Box
			onClick={onClick}
			sx={{
				display: 'flex',
				alignItems: 'center',
				cursor: 'pointer',
				borderRadius: '4px',
				padding: 1,
				color: isActive ? palette.text.primary : palette.text.icon,
				background: isActive
					? palette.background.level2
					: palette.background.level1,
				'&:hover': {
					background: palette.background.level2,
				},
				transition: 'background 0.05s ease-in-out',
			}}
		>
			<Box
				sx={{
					p: 1,
					display: 'flex',
					'& svg': {
						fontSize: '1.25rem',
					},
				}}
			>
				{icon}
			</Box>
			<Collapse open={open} orientation="horizontal">
				<Box
					sx={{
						paddingRight: 4,
						paddingLeft: 1.5,
					}}
				>
					<Typography
						level="title-lg"
						sx={{
							color: isActive ? palette.text.primary : palette.text.icon,
							fontWeight: 600,
						}}
					>
						{label}
					</Typography>
				</Box>
			</Collapse>
		</Box>
	);
};

export default NavDrawerChipRender;
