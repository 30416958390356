import { gql } from '@apollo/client';
import { ClientContext, ClientContextFragment } from './ClientContext';
import { getLazyQueryHook, getQueryHook } from '../hooks';
import { ProfileDetailFragment } from '../ProfileDetail/ProfileDetail';

export const ClientContextQuery = gql`
	${ClientContextFragment}
	${ProfileDetailFragment}
	query ClientContextQuery {
		ClientContext {
			...ClientContextFragment
		}
	}
`;

export const clientcontextSchema = {
	get: getQueryHook<{ ClientContext: ClientContext }, {}>(ClientContextQuery),
	getLazy: getLazyQueryHook<{ ClientContext: ClientContext }, {}>(
		ClientContextQuery
	),
};
