import React, { PropsWithChildren, createContext, useContext } from 'react';
import { ClientContext } from '../../schema/ClientContext/ClientContext';
import { schema } from '../../schema';
import { useAuthContext } from '../AuthProvider';

export interface ClientContextProps {
	clientContext: ClientContext | null;
	error: Error | undefined;
	loading: boolean;
}

export const Context = createContext<ClientContextProps>({
	clientContext: null,
	error: undefined,
	loading: false,
});

const ClientContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const { user } = useAuthContext();

	const { data, error, loading } = schema.clientContext.get({
		skip: !user,
		fetchPolicy: 'network-only',
	});

	const value: ClientContextProps = {
		loading,
		error,
		clientContext: data ? data.ClientContext : null,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ClientContextProvider;

export const useClientContext = () => useContext(Context);
