import React, { FC } from 'react';
import { ProfileDetailCreate } from '../../../../schema/ProfileDetail/ProfileDetailCreate';
import {
	Box,
	Button,
	Card,
	Divider,
	FormControl,
	FormLabel,
	Stack,
	Textarea,
	Tooltip,
	Typography,
} from '@mui/joy';
import { ProfileType } from '../../../../schema/ProfileType/ProfileType';
import { MdExpandMore } from 'react-icons/md';
import DayForm from './components/DayForm';

export interface AvailabilityFormProps {
	value: ProfileDetailCreate;
	onChange: (value: Partial<ProfileDetailCreate>) => void;
	onNext: () => void;
}

const AvailabilityForm: FC<AvailabilityFormProps> = ({
	value,
	onChange,
	onNext,
}) => {
	return (
		<Box sx={{ minWidth: 400 }}>
			<Stack direction="column" gap={2}>
				<DayForm
					label="Monday"
					value={value.availability.monday}
					onChange={(val) => {
						onChange({
							availability: {
								...value.availability,
								monday: val,
							},
						});
					}}
				/>
				<Divider />
				<DayForm
					label="Tuesday"
					value={value.availability.tuesday}
					onChange={(val) => {
						onChange({
							availability: {
								...value.availability,
								tuesday: val,
							},
						});
					}}
				/>
				<Divider />
				<DayForm
					label="Wednesday"
					value={value.availability.wednesday}
					onChange={(val) => {
						onChange({
							availability: {
								...value.availability,
								wednesday: val,
							},
						});
					}}
				/>
				<Divider />
				<DayForm
					label="Thursday"
					value={value.availability.thursday}
					onChange={(val) => {
						onChange({
							availability: {
								...value.availability,
								thursday: val,
							},
						});
					}}
				/>
				<Divider />
				<DayForm
					label="Friday"
					value={value.availability.friday}
					onChange={(val) => {
						onChange({
							availability: {
								...value.availability,
								friday: val,
							},
						});
					}}
				/>
			</Stack>
			<Box p={1} />
			<Box sx={{ display: 'flex' }}>
				<Tooltip arrow title={!value.bio ? 'Please enter a bio' : ''}>
					<Box>
						<Button color="success" disabled={!value.bio} onClick={onNext}>
							Let's go!
						</Button>
					</Box>
				</Tooltip>
			</Box>
		</Box>
	);
};

export default AvailabilityForm;
