import { bookingSchema } from './Booking/operations';
import { clientcontextSchema } from './ClientContext/operations';
import { organizationSchema } from './Organization/operations';
import { profiledetailSchema } from './ProfileDetail/operations';
import { relationshipSchema } from './Relationship/operations';

export const schema = {
	booking: bookingSchema,
	clientContext: clientcontextSchema,
	organization: organizationSchema,
	profileDetail: profiledetailSchema,
	relationship: relationshipSchema,
};
