import React, { ReactNode, useEffect } from 'react';
import Dashboard from '../../components/layout/Dashboard';
import RouteSection, { RouteConfig } from '../../routing/RouteSection';
import {
	MdAccountCircle,
	MdCalendarToday,
	MdFavorite,
	MdHome,
	MdPeople,
} from 'react-icons/md';
import Home from './pages/Home';
import Account from './pages/Account';
import { useClientContext } from '../../providers/ClientContextProvider';
import { ProfileType } from '../../schema/ProfileType/ProfileType';
import Requests from './pages/Requests';
import Mentors from './pages/Mentors';
import Bookings from './pages/Bookings';
import Feedback from './pages/Feedback';
import { schema } from '../../schema';
import { Box } from '@mui/joy';

export interface AppRouteConfig extends RouteConfig {
	nav?: {
		icon: ReactNode;
		label: string;
		bottom?: true;
		badge?: () => Promise<number>;
	};
}

export const useAppRoutes = (): {
	routes: Record<string, AppRouteConfig>;
	error: Error | null;
	loading: boolean;
} => {
	const [getRequests] = schema.relationship.listLazy({
		variables: {
			filter: {
				hasResponded: false,
			},
		},
	});

	const { loading, clientContext, error } = useClientContext();

	const fromStorage = localStorage.getItem('ProfileType');

	const [type, setType] = React.useState<ProfileType | null>(
		fromStorage === ProfileType.Mentee
			? ProfileType.Mentee
			: fromStorage === ProfileType.Mentor
			? ProfileType.Mentor
			: null
	);

	useEffect(() => {
		if (clientContext && clientContext.profileDetail) {
			setType(clientContext.profileDetail.type);
		}
	}, [clientContext]);

	useEffect(() => {
		if (type) {
			localStorage.setItem('ProfileType', type);
		}
	}, [type]);

	const mentorRoutes: Record<string, AppRouteConfig> = {
		mentors: {
			path: 'requests/*',
			element: <Requests />,
			type: 'private',
			nav: {
				icon: <MdPeople />,
				label: 'Requests',
				badge: async () => {
					const { data } = await getRequests();
					return data?.RelationshipList?.length || 0;
				},
			},
		},
	};

	const menteeRoutes: Record<string, AppRouteConfig> = {
		mentors: {
			path: 'mentors/*',
			element: <Mentors />,
			type: 'private',
			nav: {
				icon: <MdPeople />,
				label: 'Mentors',
			},
		},
	};

	const appRoutes: Record<string, AppRouteConfig> = !type
		? {
				catch: {
					index: true,
					type: 'private',
					element: <Box />,
				},
		  }
		: {
				home: {
					index: true,
					element: <Home />,
					type: 'private',
					nav: {
						icon: <MdHome />,
						label: 'Home',
					},
				},
				...(type === ProfileType.Mentor ? mentorRoutes : menteeRoutes),
				bookings: {
					path: 'bookings/*',
					element: <Bookings />,
					type: 'private',
					nav: {
						icon: <MdCalendarToday />,
						label: 'Bookings',
					},
				},
				feedback: {
					path: 'feedback/*',
					element: <Feedback />,
					type: 'private',
					nav: {
						icon: <MdFavorite />,
						label: 'Feedback',
					},
				},
				account: {
					path: 'account/*',
					element: <Account />,
					type: 'private',
					nav: {
						icon: <MdAccountCircle />,
						label: 'Account',
						bottom: true,
					},
				},
		  };

	return {
		routes: appRoutes,
		error: error || null,
		loading,
	};
};

const App: React.FC = () => {
	const { routes } = useAppRoutes();
	return (
		<Dashboard>
			<RouteSection>{routes}</RouteSection>
		</Dashboard>
	);
};

export default App;
