import { Identified } from '../Identified/Identified';
import { Response } from '../Response/Response';
import { ProfileDetail } from '../ProfileDetail/ProfileDetail';
import { ProfileType } from '../ProfileType/ProfileType';
import { gql } from '@apollo/client';

export class Relationship extends Identified {
	origin!: ProfileType;
	mentor!: ProfileDetail;
	mentee!: ProfileDetail;
	dateCreated!: number;
	note!: string;
	response!: Response | null;
}

export const RelationshipFragment = gql`
	fragment RelationshipFragment on Relationship {
		_id
		origin
		mentor {
			...ProfileDetailFragment
		}
		mentee {
			...ProfileDetailFragment
		}
		dateCreated
		note
		response {
			...ResponseFragment
		}
	}
`;
