import React, { FC } from 'react';
import { ProfileDetailCreate } from '../../../../schema/ProfileDetail/ProfileDetailCreate';
import { Box, Button, Tooltip } from '@mui/joy';
import EducationEntry from './components/EducationEntry';
import { MdAdd, MdExpandMore } from 'react-icons/md';

export interface EducationFormProps {
	value: ProfileDetailCreate;
	onChange: (value: Partial<ProfileDetailCreate>) => void;
	onNext: () => void;
}

const EducationForm: FC<EducationFormProps> = ({ value, onChange, onNext }) => {
	const getHoldup = () => {
		const hasMultiple = value.education.length > 1;
		const multileString = !hasMultiple ? '' : ' for each education entry';
		if (value.education.some((ed) => !ed.school))
			return 'Please enter a school' + multileString;
		else if (value.education.some((ed) => !ed.degree))
			return 'Please enter a degree' + multileString;
		else if (value.education.some((ed) => !ed.year))
			return 'Please enter a graduation year' + multileString;
		else return null;
	};

	const holdup = getHoldup();
	return (
		<Box
			sx={{
				display: 'flex',
				flexFlow: 'column',
				gap: 2,
			}}
		>
			{value.education.map((edu, i) => (
				<EducationEntry
					value={edu}
					onChange={(val) => {
						const copy = [...value.education];

						if (!val) copy.splice(i, 1);
						else copy[i] = val;

						onChange({
							education: copy,
						});
					}}
				/>
			))}
			<Box>
				<Button
					onClick={() => {
						onChange({
							education: [
								...value.education,
								{
									school: '',
									degree: '',
									year: 2023,
								},
							],
						});
					}}
					startDecorator={<MdAdd />}
					color="neutral"
					variant="soft"
				>
					Add Education
				</Button>
			</Box>
			<Box />
			<Box sx={{ display: 'flex' }}>
				<Tooltip title={holdup || ''} arrow>
					<Box>
						<Button
							disabled={Boolean(holdup)}
							onClick={onNext}
							endDecorator={<MdExpandMore />}
						>
							Next
						</Button>
					</Box>
				</Tooltip>
			</Box>
		</Box>
	);
};

export default EducationForm;
