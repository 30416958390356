import React, { FC } from 'react';
import { ProfileDetailCreate } from '../../../../../../schema/ProfileDetail/ProfileDetailCreate';
import {
	Box,
	Card,
	FormControl,
	FormLabel,
	Grid,
	IconButton,
	Input,
	Select,
	Option,
} from '@mui/joy';
import { MdDeleteForever } from 'react-icons/md';
import { useScreenSize } from '../../../../../../hooks/useScreenSize';

export interface EducationEntryProps {
	value: ProfileDetailCreate['education'][number];
	onChange: (value: ProfileDetailCreate['education'][number] | null) => void;
}

const EducationEntry: FC<EducationEntryProps> = ({ value, onChange }) => {
	const { sizeIs } = useScreenSize();

	const small = sizeIs('phone');

	const today = new Date();
	const thisYear = today.getFullYear();
	const endYear = thisYear + 8;

	const last50Years = Array.from({ length: 50 }, (_, i) => endYear - i);

	return (
		<Card variant="outlined">
			<Grid container spacing={2}>
				<Grid xs={12} md={4}>
					<FormControl>
						<FormLabel>School</FormLabel>
						<Input
							placeholder="School"
							value={value.school}
							onChange={(e) => {
								onChange({
									...value,
									school: e.target.value,
								});
							}}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={4}>
					<FormControl>
						<FormLabel>Degree or Discipline</FormLabel>
						<Input
							placeholder="Degree"
							value={value.degree}
							onChange={(e) => {
								onChange({
									...value,
									degree: e.target.value,
								});
							}}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={4}>
					<Box sx={{ display: 'flex', gap: 2 }}>
						<Box sx={{ flex: 1 }}>
							<FormControl>
								<FormLabel>Graduation Year</FormLabel>
								<Select
									placeholder="Select a year"
									value={value.year}
									onChange={(e, year) => {
										if (year)
											onChange({
												...value,
												year,
											});
									}}
								>
									{last50Years.map((year) => (
										<Option value={year}>{year}</Option>
									))}
								</Select>
							</FormControl>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexFlow: 'column',
								justifyContent: 'flex-end',
							}}
						>
							<IconButton
								variant="soft"
								onClick={() => {
									onChange(null);
								}}
							>
								<MdDeleteForever />
							</IconButton>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Card>
	);
};

export default EducationEntry;
