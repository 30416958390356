import {
	DocumentNode,
	LazyQueryHookOptions,
	MutationHookOptions,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useMutation,
	useQuery,
} from '@apollo/client';

export const getQueryHook =
	<Data, Args extends OperationVariables>(document: DocumentNode) =>
	(options: QueryHookOptions<Data, Args> = {}) =>
		useQuery<Data, Args>(document, options);

export const getLazyQueryHook =
	<Data, Args extends OperationVariables>(document: DocumentNode) =>
	(options: LazyQueryHookOptions<Data, Args> = {}) =>
		useLazyQuery<Data, Args>(document, options);

export const getMutationHook =
	<Data, Args extends OperationVariables>(document: DocumentNode) =>
	(options: MutationHookOptions<Data, Args> = {}) =>
		useMutation<Data, Args>(document, options);
