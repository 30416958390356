import { Box, Button, Card, Typography } from '@mui/joy';
import React from 'react';
import AppNav from '../../components/AppNav';
import { MdCheckCircle } from 'react-icons/md';
import { useClientContext } from '../../../../providers/ClientContextProvider';
import { ProfileType } from '../../../../schema/ProfileType/ProfileType';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
	const { clientContext } = useClientContext();
	const nav = useNavigate();

	return (
		<AppNav
			title="Welcome to MentorU"
			banner={
				<Box>
					<Box p={1} />
					<Card color="success" variant="soft">
						<Box sx={{ display: 'flex', gap: 2 }}>
							<Box
								sx={{
									paddingTop: 1,
								}}
							>
								<MdCheckCircle style={{ fontSize: '1.25rem' }} />
							</Box>
							<Box>
								<Typography level="h4">
									You've been admitted to the Spring 2024 Mentorship Cohort!
								</Typography>
								<Typography>
									After finishing your profile, feel free to start browsing
									mentors.
								</Typography>
							</Box>
							{clientContext &&
							clientContext.profileDetail &&
							clientContext.profileDetail.type === ProfileType.Mentor ? null : (
								<Button
									onClick={() => nav('mentors')}
									color="success"
									variant="soft"
								>
									Search Mentors
								</Button>
							)}
						</Box>
					</Card>
					<Box p={1} />
				</Box>
			}
		>
			<Box p={2} />
		</AppNav>
	);
};

export default Home;
