import React from 'react';
import RouteSection from '../../../../routing/RouteSection';
import MentorList from './components/MentorList';
import MentorPage from './components/MentorPage';

const Mentors: React.FC = () => {
	return (
		<RouteSection>
			{{
				mentorList: {
					index: true,
					type: 'private',
					element: <MentorList />,
				},
				mentorDetail: {
					path: ':uid',
					type: 'private',
					element: <MentorPage />,
				},
			}}
		</RouteSection>
	);
};

export default Mentors;
