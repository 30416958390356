import React, { FC } from 'react';
import { RelationshipUpdate } from '../../../../../../../../../../schema/Relationship/RelationshipUpdate';

export interface ManageProps {
	value: RelationshipUpdate;
	onChange: (value: RelationshipUpdate | null) => void;
}

const Manage: FC<ManageProps> = ({ value, onChange }) => {
	return <React.Fragment></React.Fragment>;
};

export default Manage;
