import React, { FC } from 'react';
import { ProfileDetailCreate } from '../../../../schema/ProfileDetail/ProfileDetailCreate';
import { Box, Button, Tooltip } from '@mui/joy';
import { MdAdd, MdCheck } from 'react-icons/md';
import ExperienceEntry from './components/ExperienceEntry';

export interface ExperienceFormProps {
	value: ProfileDetailCreate;
	onChange: (value: Partial<ProfileDetailCreate>) => void;
	onNext: () => void;
	loading: boolean;
}

const ExperienceForm: FC<ExperienceFormProps> = ({
	value,
	loading,
	onChange,
	onNext,
}) => {
	const getHoldup = () => {
		const hasMultiple = value.organizationRoles.length > 1;
		const multileString = !hasMultiple ? '' : ' for each experience entry';
		if (value.organizationRoles.some((ed) => !ed.organization))
			return 'Please enter an organization name' + multileString;
		else if (value.organizationRoles.some((ed) => !ed.role))
			return 'Please enter a role' + multileString;
		else if (value.organizationRoles.some((ed) => !ed.dateStarted))
			return 'Please enter a start year' + multileString;
		else return null;
	};

	const holdup = getHoldup();
	return (
		<Box
			sx={{
				display: 'flex',
				flexFlow: 'column',
				gap: 2,
			}}
		>
			{value.organizationRoles.map((edu, i) => (
				<ExperienceEntry
					value={edu}
					onChange={(val) => {
						const copy = [...value.organizationRoles];

						if (!val) copy.splice(i, 1);
						else copy[i] = val;

						onChange({
							organizationRoles: copy,
						});
					}}
				/>
			))}
			<Box>
				<Button
					onClick={() => {
						onChange({
							organizationRoles: [
								...value.organizationRoles,
								{
									organization: '',
									role: '',
									dateStarted: Date.now(),
									dateEnded: null,
								},
							],
						});
					}}
					startDecorator={<MdAdd />}
					color="neutral"
					variant="soft"
				>
					Add Experience
				</Button>
			</Box>
			<Box />
			<Box sx={{ display: 'flex' }}>
				<Tooltip title={holdup || ''} arrow>
					<Box>
						<Button
							loading={loading}
							color="primary"
							disabled={Boolean(holdup)}
							onClick={onNext}
							endDecorator={<MdCheck />}
						>
							Next
						</Button>
					</Box>
				</Tooltip>
			</Box>
		</Box>
	);
};

export default ExperienceForm;
