import React from 'react';

export type Corner = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export interface PositionedProps {
	corner: Corner;
}

const Positioned = <P extends object>(
	WrappedComponent: React.ComponentType<P>
): React.FC<P & PositionedProps> => {
	return ({ corner, ...props }: PositionedProps & P) => {
		let style: React.CSSProperties = { position: 'absolute' };

		switch (corner) {
			case 'top-left':
				style.top = 0;
				style.left = 0;
				break;
			case 'top-right':
				style.top = 0;
				style.right = 0;
				break;
			case 'bottom-left':
				style.bottom = 0;
				style.left = 0;
				break;
			case 'bottom-right':
				style.bottom = 0;
				style.right = 0;
				break;
		}

		return (
			<div style={style}>
				<WrappedComponent {...(props as P)} />
			</div>
		);
	};
};

export default Positioned;
