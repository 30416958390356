import React, { FC } from 'react';
import { ProfileDetail } from '../../../../../../schema/ProfileDetail/ProfileDetail';
import { Box, Card, Skeleton, Typography } from '@mui/joy';
import { useScreenSize } from '../../../../../../hooks/useScreenSize';

export interface ExperienceProps {
	profile: ProfileDetail | null;
}

const Experience: FC<ExperienceProps> = ({ profile }) => {
	const { sizeIs } = useScreenSize();

	const yearFromUnix = (unix: number) => {
		const date = new Date(unix);
		return date.getFullYear();
	};

	if (!profile) {
		return (
			<Box>
				<Skeleton width={sizeIs('phone') ? '100%' : '320px'} height="220px" />
			</Box>
		);
	}
	return (
		<Box
			sx={{
				display: 'flex',
				flexFlow: 'column',
				gap: 1,
			}}
		>
			{profile.organizationRoles.map((job) => (
				<Card key={job.organization._id}>
					<Typography level="body-lg" sx={{ fontWeight: 'bold' }}>
						{job.role} @ {job.organization.name}
					</Typography>
					<Typography>
						{yearFromUnix(job.dateStarted)} -{' '}
						{job.dateEnded ? yearFromUnix(job.dateEnded) : 'Present'}
					</Typography>
				</Card>
			))}
		</Box>
	);
};

export default Experience;
