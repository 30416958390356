import { Box } from '@mui/joy';
import React, { FC, ReactNode } from 'react';
import Stage from './components/Stage';

export interface StagesProps {
	active: number;
	children: {
		label: string;
		completed: boolean;
		onClick: () => void;
		content: ReactNode;
	}[];
}

const Stages: FC<StagesProps> = ({ children: stages, active }) => {
	return (
		<Box>
			{stages.map((stage, index) => (
				<Stage
					last={index === stages.length - 1}
					active={active}
					key={`stage:${index}`}
					{...stage}
					index={index}
				>
					{stage.content}
				</Stage>
			))}
		</Box>
	);
};

export default Stages;
