import React, { FC } from 'react';
import { ProfileDetail } from '../../../../../../../../schema/ProfileDetail/ProfileDetail';
import {
	AspectRatio,
	Box,
	Button,
	Card,
	CardContent,
	CardOverflow,
	Grid,
	Typography,
} from '@mui/joy';
import { Relationship } from '../../../../../../../../schema/Relationship/Relationship';
import { useNavigate } from 'react-router-dom';

export interface MentorCardProps {
	children: ProfileDetail;
	relationships: Relationship[];
	onConnect: () => void;
}

const MentorCard: FC<MentorCardProps> = ({
	children: mentor,
	relationships,
	onConnect,
}) => {
	const nav = useNavigate();

	const relationship =
		relationships.find(
			(relationship) => relationship.mentor.uid === mentor.uid
		) || null;

	return (
		<Grid
			xs={12}
			sm={6}
			lg={4}
			sx={{
				display: 'flex',
				flexFlow: 'column',
			}}
		>
			<Card
				variant="outlined"
				sx={{ flex: 1, display: 'flex', flexFlow: 'column' }}
			>
				<CardOverflow>
					<AspectRatio ratio="2">
						<img src={mentor.photoURL || ''} loading="lazy" alt={mentor.name} />
					</AspectRatio>
				</CardOverflow>
				<CardContent
					sx={{
						flex: 1,
						display: 'flex',
						flexFlow: 'column',
					}}
				>
					<Typography level="title-md">{mentor.name}</Typography>
					<Typography level="body-sm">{mentor.tag}</Typography>
					<Box p={1} />
					<Typography level="body-sm">{mentor.bio}</Typography>
					<Box p={1} sx={{ flex: 1 }} />
					<Grid container spacing={1}>
						<Grid sm={12} md={6}>
							<Button
								onClick={() => nav(mentor.uid)}
								size="sm"
								fullWidth
								variant="outlined"
								color="neutral"
							>
								More Info
							</Button>
						</Grid>
						{!relationship && (
							<Grid sm={12} md={6}>
								<Button
									size="sm"
									onClick={onConnect}
									fullWidth
									variant="outlined"
									color="neutral"
								>
									Request Mentorship
								</Button>
							</Grid>
						)}
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default MentorCard;
