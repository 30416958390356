import {
	Avatar,
	Box,
	Button,
	IconButton,
	Skeleton,
	Typography,
	useColorScheme,
	useTheme,
} from '@mui/joy';
import React from 'react';
import { MdDarkMode, MdLightMode, MdMenu } from 'react-icons/md';
import { useAuthContext } from '../../../../../providers/AuthProvider';
import UserAvatar from '../../../../display/UserAvatar';
import { useScreenSize } from '../../../../../hooks/useScreenSize';
import NavDrawer from './components/NavDrawer';
import { useAppRoutes } from '../../../../../scenes/App';

export interface TopbarProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

const Topbar: React.FC<TopbarProps> = ({ open, setOpen }) => {
	const { user } = useAuthContext();
	const { palette } = useTheme();
	const { mode, setMode } = useColorScheme();
	const { sizeIs } = useScreenSize();
	const [navOpen, setNavOpen] = React.useState(false);
	const routes = useAppRoutes();
	return (
		<Box
			sx={{
				p: 1,
				background: palette.background.level1,
				borderBottom: `1px solid ${palette.divider}`,
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<IconButton
				onClick={() => {
					if (sizeIs('phone')) setNavOpen(true);
					else setOpen(!open);
				}}
			>
				<MdMenu style={{ fontSize: '1.25rem' }} />
			</IconButton>
			<Box
				sx={{
					width: '32px',
				}}
			/>
			<Typography sx={{ fontWeight: 700 }} level="h3">
				MentorU
			</Typography>
			<Box
				sx={{
					flex: 1,
				}}
			/>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<IconButton
					onClick={() => {
						setMode(mode === 'dark' ? 'light' : 'dark');
					}}
					sx={{
						borderRadius: '50%',
					}}
				>
					{mode === 'dark' ? <MdLightMode /> : <MdDarkMode />}
				</IconButton>
				<UserAvatar>{user}</UserAvatar>
			</Box>
			{sizeIs('phone') && (
				<NavDrawer open={navOpen} onClose={() => setNavOpen(false)} />
			)}
		</Box>
	);
};

export default Topbar;
