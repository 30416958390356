import React, { useEffect } from 'react';
import Page from '../../components/layout/Page';
import { useAuthContext } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

const Logout: React.FC = () => {
	const { signout } = useAuthContext();
	const nav = useNavigate();

	useEffect(() => {
		signout.handler();
		nav('/');
	});

	return <Page loading />;
};

export default Logout;
