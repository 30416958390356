import { Box, Divider, Typography, TypographyProps } from '@mui/joy';
import React, { FC } from 'react';

export interface DividerTextProps extends TypographyProps {}

const DividerText: FC<DividerTextProps> = ({ level = 'body-sm', ...props }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: 1,
			}}
		>
			<Box sx={{ flex: 1 }}>
				<Divider />
			</Box>
			<Typography level={level} {...props} />
			<Box sx={{ flex: 1 }}>
				<Divider />
			</Box>
		</Box>
	);
};

export default DividerText;
