import React, { PropsWithChildren } from 'react';
import {
	ApolloProvider as Apollo,
	ApolloClient,
	InMemoryCache,
	HttpLink,
	from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { auth } from '../AuthProvider/firebase';

import { onError } from '@apollo/client/link/error';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path, extensions }) => {
			if (extensions.code === 'UNAUTHENTICATED') {
				window.location.href = '/';
			}
		});
	// if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext(
	(request) =>
		new Promise((success, fail) => {
			if (auth.currentUser) {
				auth.currentUser.getIdToken().then((token) => {
					success({
						headers: {
							authorization: token,
						},
					});
				});
			} else {
				success({});
			}
			// do some async lookup here
			setTimeout(() => {
				success({ token: 'async found token' });
			}, 10);
		})
);

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_API_URL,
});

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: from([errorLink, authLink, httpLink]),
});

const ApolloProvider: React.FC<PropsWithChildren> = ({ children }) => {
	return <Apollo client={client}>{children}</Apollo>;
};

export default ApolloProvider;
