import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './providers/AuthProvider';
import ApolloProvider from './providers/ApolloProvider';
import ThemeProvider from './providers/ThemeProvider';
import RouteSection from './routing/RouteSection';
import Landing from './scenes/Landing';
import Logout from './scenes/Logout';
import Signup from './scenes/Signup';
import Login from './scenes/Login';
import App from './scenes/App';
import ToastProvider from './providers/ToastProvider';
import ClientContextProvider from './providers/ClientContextProvider';
import ProfileSetup from './scenes/ProfileSetup';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ApolloProvider>
				<AuthProvider>
					<ThemeProvider>
						<ToastProvider>
							<ClientContextProvider>
								<RouteSection>
									{{
										landing: {
											type: 'public',
											index: true,
											element: <Landing />,
										},
										login: {
											type: 'anonymous',
											path: 'login',
											element: <Login />,
										},
										signup: {
											type: 'anonymous',
											path: 'signup',
											element: <Signup />,
										},
										setup: {
											type: 'authenticated',
											path: 'setup',
											element: <ProfileSetup />,
										},
										logout: {
											type: 'public',
											path: 'logout',
											element: <Logout />,
										},
										app: {
											type: 'private',
											path: 'app/*',
											element: <App />,
										},
									}}
								</RouteSection>
							</ClientContextProvider>
						</ToastProvider>
					</ThemeProvider>
				</AuthProvider>
			</ApolloProvider>
		</BrowserRouter>
	</React.StrictMode>
);
