import { Box, Typography } from '@mui/joy';
import React from 'react';

const SetupHeader: React.FC = () => {
	return (
		<Box sx={{ p: 2 }}>
			<div
				style={{
					fontSize: '2rem',
				}}
			>
				👋
			</div>
			<Typography level="h1">Welcome to MentorU!</Typography>
			<Typography>We need a little more info to get you started.</Typography>
		</Box>
	);
};

export default SetupHeader;
