import React, { FC } from 'react';
import { ProfileDetailCreate } from '../../../../schema/ProfileDetail/ProfileDetailCreate';
import {
	Box,
	Button,
	Card,
	FormControl,
	FormLabel,
	Textarea,
	Tooltip,
	Typography,
} from '@mui/joy';
import { ProfileType } from '../../../../schema/ProfileType/ProfileType';
import { MdExpandMore } from 'react-icons/md';

export interface BioFormProps {
	value: ProfileDetailCreate;
	onChange: (value: Partial<ProfileDetailCreate>) => void;
	onNext: () => void;
}

const BioForm: FC<BioFormProps> = ({ value, onChange, onNext }) => {
	const sampleBios = {
		[ProfileType.Mentee]:
			"I'm a Senior in the School of Business with a vision of a future in accounting, specifically as a controller. Looking for advice on getting into the industry. In my free time, I enjoy exploring new cuisines and hiking with my dog!",
		[ProfileType.Mentor]:
			'With 5+ years of experience in FinTech, I love learning how people think and behave, and I leverage research to design user-centered products and experiences which solve both user and business problems.',
		[ProfileType.Admin]: '',
	};

	return (
		<Box>
			<FormControl>
				<FormLabel>Bio</FormLabel>
				<Textarea
					placeholder="Bio"
					minRows={3}
					value={value.bio}
					onChange={(e) => {
						onChange({
							bio: e.target.value,
						});
					}}
				/>
			</FormControl>
			<Box p={1} />
			<FormControl>
				<FormLabel>Sample Bio</FormLabel>
				<Card variant="soft" sx={{ padding: 1 }}>
					<Typography level="body-sm">{sampleBios[value.type]}</Typography>
				</Card>
			</FormControl>
			<Box p={1} />
			<Box sx={{ display: 'flex' }}>
				<Tooltip arrow title={!value.bio ? 'Please enter a bio' : ''}>
					<Box>
						<Button
							endDecorator={<MdExpandMore />}
							disabled={!value.bio}
							onClick={onNext}
						>
							Next
						</Button>
					</Box>
				</Tooltip>
			</Box>
		</Box>
	);
};

export default BioForm;
