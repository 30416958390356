import React, { FC, ReactNode, useEffect } from 'react';
import { useToast } from '../../../providers/ToastProvider';
import { Box, IconButton, Typography, useTheme } from '@mui/joy';
import {
	MdCheckCircle,
	MdClear,
	MdError,
	MdInfo,
	MdWarningAmber,
} from 'react-icons/md';

export interface ToastBarProps {
	variant: 'success' | 'warning' | 'danger' | 'info';
	message: string;
	duration: number;
	timestamp: number;
	action?: ReactNode;
}

const icons: Record<ToastBarProps['variant'], ReactNode> = {
	success: <MdCheckCircle />,
	warning: <MdWarningAmber />,
	danger: <MdError />,
	info: <MdInfo />,
};

const ToastBar: FC<ToastBarProps> = ({
	variant,
	message,
	duration,
	action,
	timestamp,
}) => {
	const { drop } = useToast();
	const { palette } = useTheme();

	const icon = icons[variant];

	return (
		<Box
			sx={{
				background: palette.background.level1,
				borderRadius: '4px',
				p: 1,
				display: 'flex',
				alignItems: 'center',
				gap: 1,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					'& svg': {
						color:
							palette[
								variant === 'danger'
									? 'danger'
									: variant === 'warning'
									? 'warning'
									: variant === 'info'
									? 'primary'
									: 'success'
							][500],
						fontSize: '1.1rem',
					},
				}}
			>
				{icon}
			</Box>
			<Box>
				<Typography
					sx={{
						paddingRight: 2,
					}}
					level="title-md"
				>
					{message}
				</Typography>
			</Box>
			{action || (
				<IconButton
					onClick={() => {
						drop(timestamp);
					}}
				>
					<MdClear />
				</IconButton>
			)}
		</Box>
	);
};

export default ToastBar;
