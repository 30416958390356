import React, { FC } from 'react';
import RouteElement, { RouteElementProps } from '../RouteElement';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../../scenes/NotFound';

export interface RouteConfig extends RouteElementProps {
	index?: boolean;
	path?: string;
}

export interface RouteSectionProps {
	children: Record<string, RouteConfig>;
}

const RouteSection: FC<RouteSectionProps> = ({ children: routes }) => {
	return (
		<Routes>
			{Object.entries(routes).map(([key, route]) => {
				return (
					<Route
						key={`route_${key}`}
						index={route.index}
						path={route.path}
						element={<RouteElement type={route.type} element={route.element} />}
					/>
				);
			})}
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default RouteSection;
