import { gql } from '@apollo/client';
import { ProfileDetail } from '../ProfileDetail/ProfileDetail';

export class ClientContext {
	profileDetail!: ProfileDetail | null;
}

export const ClientContextFragment = gql`
	fragment ClientContextFragment on ClientContext {
		profileDetail {
			...ProfileDetailFragment
		}
	}
`;
