import React, { FC } from 'react';
import { RelationshipCreate } from '../../../../../../../../../../schema/Relationship/RelationshipCreate';
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	IconButton,
	Textarea,
	Typography,
} from '@mui/joy';
import { ProfileDetail } from '../../../../../../../../../../schema/ProfileDetail/ProfileDetail';
import { MdClear } from 'react-icons/md';

export interface InviteProps {
	profile: ProfileDetail;
	value: RelationshipCreate;
	onChange: (value: RelationshipCreate | null) => void;
	onSubmit: () => void;
	loading: boolean;
}

const Invite: FC<InviteProps> = ({
	profile,
	value,
	onChange,
	onSubmit,
	loading,
}) => {
	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<Typography level="h3">{`Confirm your request with ${profile.name}`}</Typography>
				<IconButton onClick={() => onChange(null)}>
					<MdClear />
				</IconButton>
			</Box>
			<Box p={2} />
			<FormControl>
				<FormLabel>Add a note to your request (Optional)</FormLabel>
				<Textarea
					placeholder={`Tips on getting accepted by a Mentor:
     •   Introduce yourself
     •   Share your goal
     •   Share your experience`}
					minRows={3}
					value={value.note}
					onChange={(e) => {
						onChange({
							...value,
							note: e.target.value,
						});
					}}
				/>
			</FormControl>
			<Box p={2} />
			<Button loading={loading} onClick={onSubmit} size="lg" fullWidth>
				Confirm
			</Button>
		</Box>
	);
};

export default Invite;
