import { Box, Drawer, useColorScheme, useTheme } from '@mui/joy';
import React, { FC } from 'react';
import { useAppRoutes } from '../../../../../../../scenes/App';
import NavDrawerChip from './components/NavDrawerChip';
import NavDrawerChipRender from './components/NavDrawerChip/components/NavDrawerChipRender';
import { MdDarkMode, MdLightMode, MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export interface NavDrawerProps {
	open: boolean;
	onClose: () => void;
}

const NavDrawer: FC<NavDrawerProps> = ({ open, onClose }) => {
	const { routes } = useAppRoutes();
	const nav = useNavigate();
	const { palette } = useTheme();
	const { mode, setMode } = useColorScheme();

	return (
		<Drawer open={open} onClose={onClose}>
			<Box
				sx={{
					height: '100%',
					background: palette.background.level1,
					display: 'flex',
					flexFlow: 'column',
					gap: 2,
					p: 2,
				}}
			>
				{Object.entries(routes).map(([key, route]) => (
					<NavDrawerChip onNav={() => onClose()} key={`nav:${key}`}>
						{route}
					</NavDrawerChip>
				))}
				<Box sx={{ flex: 1 }} />
				<NavDrawerChipRender
					onClick={() => {
						setMode(mode === 'light' ? 'dark' : 'light');
					}}
					label={mode === 'light' ? 'Dark Mode' : 'Light Mode'}
					icon={mode === 'light' ? <MdDarkMode /> : <MdLightMode />}
				/>
				<NavDrawerChipRender
					onClick={() => {
						onClose();
						nav('/logout');
					}}
					label="Logout"
					icon={<MdLogout />}
				/>
			</Box>
		</Drawer>
	);
};

export default NavDrawer;
