import React, { FC } from 'react';
import { AppRouteConfig } from '../../../../../../../../../scenes/App';
import { useLocation, useNavigate } from 'react-router-dom';
import NavChip from './components/NavChip';

export interface NavProps {
	children: AppRouteConfig;
	open?: boolean;
}

const Nav: FC<NavProps> = ({ children: route, open = true }) => {
	const { pathname } = useLocation();
	const nav = useNavigate();

	const isActive = route.index
		? pathname === '/app'
		: pathname.includes(route?.path?.replace('/*', '') || '');

	if (!route.nav) return null;

	return (
		<NavChip
			open={open}
			onClick={() => {
				if (route.path) {
					nav(route.path.replace('/*', ''));
				} else if (route.index) nav('/app');
			}}
			isActive={isActive}
			{...route.nav}
		/>
	);
};

export default Nav;
