import {
	Box,
	Divider,
	LinearProgress,
	Tab,
	TabList,
	Tabs,
	Typography,
	useTheme,
} from '@mui/joy';
import React, {
	useEffect,
	PropsWithChildren,
	ReactNode,
	useRef,
	useState,
} from 'react';

export interface AppNavigation<T> {
	options: T[];
	current: T;
	setCurrent: (current: T) => void;
	props: (option: T) => { id: string; label: string };
}

export interface AppNavProps<T> {
	loading?: boolean;
	error?: Error | null;
	action?: ReactNode;
	banner?: ReactNode;
	navigation?: AppNavigation<T>;
	title: string;
}

export const navWidth = 1120;

const AppNav = <T,>({
	loading = false,
	error = null,
	banner,
	action,
	navigation,
	title,
	children,
}: PropsWithChildren<AppNavProps<T>>) => {
	const { palette, shadow } = useTheme();

	const [scolled, setScrolled] = useState(false);

	const scrollRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (scrollRef.current) {
				setScrolled(scrollRef.current.scrollTop > 0);
			}
		};

		if (scrollRef.current) {
			scrollRef.current.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (scrollRef.current) {
				scrollRef.current.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				flexFlow: 'column',
			}}
		>
			{loading && (
				<Box
					sx={{
						position: 'absolute',
						left: 0,
						top: 0,
						right: 0,
					}}
				>
					<LinearProgress color="neutral" size="sm" />
				</Box>
			)}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					boxShadow: scolled ? shadow.lg : undefined,
					borderBottom: scolled ? `1px solid ${palette.divider}` : undefined,
					zIndex: 5,
				}}
			>
				<Box
					sx={{ flex: 1, maxWidth: navWidth, paddingLeft: 2, paddingRight: 2 }}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: navigation ? 'flex-end' : 'center',
						}}
					>
						<Box>
							<Box p={0.5} />
							{banner}
							<Typography level="h1">{title}</Typography>
							{navigation && (
								<Box>
									<Box p={0.5} />
									<Tabs
										value={navigation.props(navigation.current).id}
										onChange={(e, value) => {
											const option = navigation.options.find(
												(option) => navigation.props(option).id === value
											);
											if (option) {
												navigation.setCurrent(option);
											}
										}}
									>
										<TabList disableUnderline>
											{navigation.options.map((option, index) => {
												const { id, label } = navigation.props(option);
												return (
													<Tab
														key={`tab_${id}`}
														value={id}
														variant="plain"
														color="neutral"
													>
														{label}
													</Tab>
												);
											})}
										</TabList>
									</Tabs>
								</Box>
							)}
						</Box>
						{action && <Box sx={{ p: 1 }}>{action}</Box>}
					</Box>
					{navigation && <Divider />}
				</Box>
			</Box>
			<Box
				ref={scrollRef}
				sx={{
					flex: 1,
					overflow: 'auto',
					display: 'flex',
					justifyContent: 'center',
					p: 1,
				}}
			>
				<Box
					sx={{ flex: 1, maxWidth: navWidth, paddingLeft: 2, paddingRight: 2 }}
				>
					{children}
					<Box p={4} />
				</Box>
			</Box>
		</Box>
	);
};

export default AppNav;
