import React, { FC } from 'react';
import { Relationship } from '../../../../../../../../schema/Relationship/Relationship';
import { Box, Button, Card, Typography } from '@mui/joy';
import { format, fromUnixTime } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { schema } from '../../../../../../../../schema';
import { useToast } from '../../../../../../../../providers/ToastProvider';
import { RelationshipListQuery } from '../../../../../../../../schema/Relationship/operations';

export interface RequestCardProps {
	children: Relationship;
}

const RequestCard: FC<RequestCardProps> = ({ children: relationship }) => {
	const { enqueue } = useToast();
	const date = fromUnixTime(relationship.dateCreated);
	const nav = useNavigate();

	const [accept, { loading }] = schema.relationship.update({
		variables: {
			id: relationship._id,
			data: {
				response: {
					accepted: true,
					note: '',
				},
			},
		},
		onCompleted: () => {
			enqueue({
				message: `You accepted ${relationship.mentee.name}'s request.`,
				variant: 'success',
			});
		},
		refetchQueries: [RelationshipListQuery],
	});

	return (
		<Card
			sx={{
				display: 'flex',
				flexFlow: 'row',
			}}
		>
			<Box>
				<img
					style={{
						objectFit: 'cover',
						borderRadius: '4px',
						height: '106px',
						width: '106px',
					}}
					src={relationship.mentee.photoURL || ''}
					alt={relationship.mentee.name}
				/>
			</Box>
			<Box>
				<Typography level="h4">
					{`${relationship.mentee.name} requested your mentorship.`}
				</Typography>
				<Typography>{format(date, 'MMMM do')}</Typography>
				<Box p={1} />
				<Box
					sx={{
						display: 'flex',
						gap: 1,
					}}
				>
					<Button
						color="neutral"
						variant="soft"
						onClick={() => {
							nav(relationship.mentee.uid);
						}}
					>
						View Profile
					</Button>
					<Button
						loading={loading}
						onClick={() => {
							accept();
						}}
					>
						Accept
					</Button>
				</Box>
			</Box>
		</Card>
	);
};

export default RequestCard;
