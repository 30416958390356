import { Box } from '@mui/joy';
import React, { PropsWithChildren } from 'react';
import { useScreenSize } from '../../../hooks/useScreenSize';

const Centered: React.FC<PropsWithChildren> = ({ children }) => {
	const { size } = useScreenSize();

	const small = size === 'phone';

	if (small)
		return (
			<Box
				sx={{
					height: '100%',
					overflow: 'auto',
				}}
			>
				{children}
			</Box>
		);
	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-start',
				overflow: 'auto',
			}}
		>
			<Box>{children}</Box>
		</Box>
	);
};

export default Centered;
