import { Box, Button, Typography, useTheme } from '@mui/joy';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../providers/AuthProvider';

const LandingBar: React.FC = () => {
	const { palette } = useTheme();
	const nav = useNavigate();
	const { user } = useAuthContext();

	return (
		<Box
			sx={{
				background: palette.background.level1,
				p: 2,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Typography level="h2">MentorU</Typography>
			<Box
				sx={{
					display: 'flex',
					gap: 2,
				}}
			>
				{user ? (
					<Button onClick={() => nav('/app')} size="lg">
						Dashboard
					</Button>
				) : (
					<React.Fragment>
						<Button onClick={() => nav('/login')} size="lg" color="neutral">
							Login
						</Button>
						<Button onClick={() => nav('/signup')} size="lg">
							Signup
						</Button>
					</React.Fragment>
				)}
			</Box>
		</Box>
	);
};

export default LandingBar;
