import React, { FC, useEffect } from 'react';
import CopyButton from '../../../../components/input/CopyButton';
import { useAuthContext } from '../../../../providers/AuthProvider';
import ProfileDetail from '../../components/ProfileDetail';

export interface AccountProps {}

const Account: FC<AccountProps> = ({}) => {
	const [token, setToken] = React.useState('');

	const { user } = useAuthContext();

	useEffect(() => {
		if (user) {
			user.getIdToken().then(setToken);
		}
	}, [user]);

	const apiKey = (
		<CopyButton
			color="neutral"
			variant="soft"
			loading={!token}
			value={token}
			phrase={'Copy API Token'}
		/>
	);

	return <ProfileDetail uid={user?.uid || ''} />;
};

export default Account;
