import React, { FC } from 'react';
import {
	Box,
	Card,
	FormControl,
	FormLabel,
	Grid,
	IconButton,
	Input,
	Option,
	Select,
} from '@mui/joy';
import { MdDeleteForever } from 'react-icons/md';
import { useScreenSize } from '../../../../../../hooks/useScreenSize';
import { ProfileDetailCreate } from '../../../../../../schema/ProfileDetail/ProfileDetailCreate';

export interface ExperienceEntryProps {
	value: ProfileDetailCreate['organizationRoles'][number];
	onChange: (
		value: ProfileDetailCreate['organizationRoles'][number] | null
	) => void;
}

const ExperienceEntry: FC<ExperienceEntryProps> = ({ value, onChange }) => {
	const { sizeIs } = useScreenSize();

	const today = new Date();
	const thisYear = today.getFullYear();

	const last50Years = Array.from({ length: 50 }, (_, i) => thisYear - i);

	const getYearFromUnix = (unix: number) => {
		const date = new Date(unix);
		return date.getFullYear();
	};

	const getUnixFromYear = (year: number) => {
		const date = new Date();
		date.setFullYear(year);
		return date.getTime();
	};

	const startYear = getYearFromUnix(value.dateStarted);
	const endYear = value.dateEnded ? getYearFromUnix(value.dateEnded) : null;

	const endYears = last50Years.filter((year) => year >= startYear);

	return (
		<Card variant="outlined">
			<Grid container spacing={2}>
				<Grid xs={12} md={6}>
					<FormControl>
						<FormLabel>Organization</FormLabel>
						<Input
							placeholder="Organization"
							value={value.organization}
							onChange={(e) => {
								onChange({
									...value,
									organization: e.target.value,
								});
							}}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={6}>
					<FormControl>
						<FormLabel>Role</FormLabel>
						<Input
							placeholder="Role"
							value={value.role}
							onChange={(e) => {
								onChange({
									...value,
									role: e.target.value,
								});
							}}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl>
						<FormLabel>Year Started</FormLabel>
						<Select
							placeholder="Select a year"
							value={startYear}
							onChange={(e, val) => {
								if (val)
									onChange({
										...value,
										dateStarted: getUnixFromYear(val),
									});
							}}
						>
							{last50Years.map((year) => (
								<Option value={year}>{year}</Option>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<Box sx={{ display: 'flex', gap: 2 }}>
						<Box sx={{ flex: 1 }}>
							<FormControl>
								<FormLabel>Year Ended</FormLabel>
								<Select
									placeholder="Select a year"
									value={endYear}
									onChange={(e, val) => {
										onChange({
											...value,
											dateEnded: val ? getUnixFromYear(val) : null,
										});
									}}
								>
									<Option value={null}>Present</Option>
									{endYears.map((year) => (
										<Option value={year}>{year}</Option>
									))}
								</Select>
							</FormControl>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexFlow: 'column',
								justifyContent: 'flex-end',
							}}
						>
							<IconButton
								variant="soft"
								onClick={() => {
									onChange(null);
								}}
							>
								<MdDeleteForever />
							</IconButton>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Card>
	);
};

export default ExperienceEntry;
