import { Box, Typography } from '@mui/joy';
import React, { FC, PropsWithChildren } from 'react';
import { useScreenSize } from '../../../../../../hooks/useScreenSize';

export interface ProfileSectionProps {
	label: string;
}

const ProfileSection: FC<PropsWithChildren<ProfileSectionProps>> = ({
	label,
	children,
}) => {
	const { sizeIs } = useScreenSize();
	return (
		<Box
			sx={{
				maxWidth: sizeIs('phone') ? '100%' : '600px',
			}}
		>
			<Typography level="h4">{label}</Typography>
			<Box p={1} />
			{children}
		</Box>
	);
};

export default ProfileSection;
