import React, { useState } from 'react';
import Page from '../../components/layout/Page';
import Centered from '../../components/layout/Centered';
import SetupHeader from './components/SetupHeader';
import ExperienceForm from './components/ExperienceForm';
import DetailForm from './components/DetailForm';
import { ProfileDetailCreate } from '../../schema/ProfileDetail/ProfileDetailCreate';
import { useAuthContext } from '../../providers/AuthProvider';
import { ProfileType } from '../../schema/ProfileType/ProfileType';
import BioForm from './components/BioForm';
import Stages from '../../components/layout/Stages';
import { Box } from '@mui/joy';
import { useScreenSize } from '../../hooks/useScreenSize';
import EducationForm from './components/EducationForm';
import { schema } from '../../schema';
import { useToast } from '../../providers/ToastProvider';
import Positioned from '../../components/layout/Positioned';
import ToastBarContainer from '../../components/display/ToastBarContainer';
import { useClientContext } from '../../providers/ClientContextProvider';
import { Navigate } from 'react-router-dom';
import { ClientContextQuery } from '../../schema/ClientContext/operations';
import AvailabailityForm from './components/AvailabilityForm';

const PositionedToast = Positioned(ToastBarContainer);

const AccountSetup: React.FC = () => {
	const { clientContext } = useClientContext();
	const { user } = useAuthContext();
	const { sizeIs } = useScreenSize();
	const { enqueue } = useToast();

	const [index, setIndex] = React.useState(0);

	const [state, setState] = useState<ProfileDetailCreate>({
		firstName: user?.displayName?.split(' ')[0] || '',
		lastName: user?.displayName?.split(' ')[1] || '',
		email: user?.email || '',
		education: [
			{
				school: 'University of Utah',
				degree: '',
				year: 2023,
			},
		],
		organizationRoles: [
			{
				organization: '',
				role: '',
				dateStarted: Date.now(),
				dateEnded: null,
			},
		],
		bio: '',
		type: ProfileType.Mentee,
		availability: {
			contact: true,
			monday: [
				{
					start: 9,
					end: 12,
				},
			],
			tuesday: [
				{
					start: 9,
					end: 12,
				},
			],
			wednesday: [
				{
					start: 9,
					end: 12,
				},
			],
			thursday: [
				{
					start: 9,
					end: 12,
				},
			],
			friday: [
				{
					start: 9,
					end: 12,
				},
			],
		},
	});

	const [save, { loading }] = schema.profileDetail.create({
		variables: {
			data: state,
		},
		onCompleted: () => {
			enqueue({
				message: 'Profile created!',
				variant: 'success',
			});
		},
		onError: (e) => {
			enqueue({
				message: "Couldn't create profile",
				variant: 'danger',
			});
		},
		refetchQueries: [ClientContextQuery],
	});

	const forms = [
		{
			label: 'Account Details',
			component: (
				<DetailForm
					onNext={() => setIndex(1)}
					value={state}
					onChange={(val) => {
						setState({
							...state,
							...val,
						});
					}}
				/>
			),
		},
		{
			label: 'Bio',
			component: (
				<BioForm
					onNext={() => setIndex(2)}
					value={state}
					onChange={(val) => {
						setState({
							...state,
							...val,
						});
					}}
				/>
			),
		},
		{
			label: 'Education',
			component: (
				<EducationForm
					onNext={() => setIndex(3)}
					value={state}
					onChange={(val) => {
						setState({
							...state,
							...val,
						});
					}}
				/>
			),
		},
		{
			label: 'Experience',
			component: (
				<ExperienceForm
					loading={loading}
					onNext={() => setIndex(4)}
					value={state}
					onChange={(val) => {
						setState({
							...state,
							...val,
						});
					}}
				/>
			),
		},
		{
			label: 'Availability',
			component: (
				<AvailabailityForm
					onNext={() => {
						save();
					}}
					value={state}
					onChange={(val) => {
						setState({
							...state,
							...val,
						});
					}}
				/>
			),
		},
	];

	if (clientContext && clientContext.profileDetail)
		return <Navigate to="/app" />;

	return (
		<Page>
			<PositionedToast anchor="bottom" corner="bottom-right" />
			<Centered>
				<Box
					sx={{
						width: sizeIs('phone') ? '100%' : '650px',
					}}
				>
					<SetupHeader />
					<Stages active={index}>
						{forms.map((form, formIndex) => ({
							label: form.label,
							completed: formIndex < index,
							onClick: () => setIndex(formIndex),
							content: form.component,
						}))}
					</Stages>
					<Box p={6} />
				</Box>
			</Centered>
		</Page>
	);
};

export default AccountSetup;
