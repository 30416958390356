import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Box, LinearProgress, useTheme } from '@mui/joy';

export interface PageProps {
	loading?: boolean;
	error?: Error | null;
	ref?: React.RefObject<HTMLDivElement>;
}

const Page: FC<PropsWithChildren<PageProps>> = ({
	loading = false,
	error = null,
	children,
	ref,
}) => {
	const { palette, typography, zIndex } = useTheme();

	const extractHex = (str: string): string | null => {
		const hexRegex = /#[0-9A-Fa-f]{3,6}/;
		const match = str.match(hexRegex);
		return match ? match[0] : null;
	};

	useEffect(() => {
		const bodyColor = extractHex(palette.background.body);
		const bodyText = extractHex(palette.text.primary);

		if (bodyColor && bodyText) {
			document.body.style.backgroundColor = bodyColor;
			document.body.style.color = bodyText;
		}
	}, [palette]);

	return (
		<Box
			ref={ref}
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				background: palette.background.body,
				...typography['body-md'],
				overflow: 'hidden',
			}}
		>
			{loading && (
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						zIndex: zIndex.tooltip,
					}}
				>
					<LinearProgress size="sm" color="neutral" />
				</Box>
			)}
			{children}
		</Box>
	);
};

export default Page;
