import React from 'react';
import Page from '../../components/layout/Page';
import { Box, Button, Typography } from '@mui/joy';
import LandingBar from './components/LandingBar';
import { useAuthContext } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

const Landing: React.FC = () => {
	const { user } = useAuthContext();
	const nav = useNavigate();

	return (
		<Page>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					flexFlow: 'column',
				}}
			>
				<LandingBar />
				<Box
					sx={{
						flex: 1,
						overflow: 'auto',
						p: 3,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Box>
							<Box p={3} />
							<Typography
								sx={{
									fontSize: '3.5rem',
									fontWeight: 700,
									whiteSpace: 'pre-line',
								}}
							>
								{`Take the Leap: Connect with a
								University of Utah Mentor Today.`}
							</Typography>
							<Box p={3} />
							<Box
								sx={{
									display: 'flex',
									gap: 2,
								}}
							>
								{user ? (
									<Button onClick={() => nav('/app')} size="lg">
										Dashboard
									</Button>
								) : (
									<React.Fragment>
										<Button
											onClick={() => nav('/login')}
											size="lg"
											color="neutral"
										>
											Login
										</Button>
										<Button onClick={() => nav('/signup')} size="lg">
											Signup
										</Button>
									</React.Fragment>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Page>
	);
};

export default Landing;
