import React, { useState } from 'react';
import AppNav from '../../../../components/AppNav';
import { ProfileDetailFilter } from '../../../../../../schema/ProfileDetail/ProfileDetailFilter';
import { ProfileType } from '../../../../../../schema/ProfileType/ProfileType';
import { ProfileDetail } from '../../../../../../schema/ProfileDetail/ProfileDetail';
import { schema } from '../../../../../../schema';
import { Box, Grid } from '@mui/joy';
import MentorCard from './components/MentorCard';
import TriggerInput from '../../../../../../components/input/TriggerInput';
import { MdSearch } from 'react-icons/md';
import { useScreenSize } from '../../../../../../hooks/useScreenSize';
import RelationshipForm from './components/RelationshipForm';
import { ProfileCategory } from '../../../../../../schema/ProfileType/ProfileCategory';

const MentorList: React.FC = () => {
	const { sizeIs } = useScreenSize();

	const [filter, setFilter] = useState<ProfileDetailFilter>({
		type: ProfileType.Mentor,
		category: ProfileCategory.All,
	});

	const [mentors, setMentors] = useState<ProfileDetail[]>([]);

	const { loading, error } = schema.profileDetail.list({
		variables: { filter },
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			setMentors(data.ProfileDetailList);
		},
	});

	const {
		data: relationshipsData,
		loading: relationshipsLoading,
		error: relationshipsError,
	} = schema.relationship.list({
		variables: {
			filter: {},
		},
	});

	const relationships = relationshipsData?.RelationshipList || [];

	const [focused, setFocused] = useState<ProfileDetail | null>(null);

	return (
		<AppNav
			action={
				<TriggerInput
					sx={{
						width: !sizeIs('phone') ? 365 : '140px',
					}}
					fullWidth={sizeIs('phone')}
					placeholder={
						sizeIs('phone') ? 'Search' : 'Search by name, company, or role'
					}
					startDecorator={<MdSearch />}
					value={filter.phrase || ''}
					onChange={(phrase) => {
						setFilter({
							...filter,
							phrase: phrase || null,
						});
					}}
				/>
			}
			navigation={{
				current: filter.category!,
				setCurrent: (category) => {
					setFilter({
						...filter,
						category,
					});
				},
				options: [
					ProfileCategory.All,
					ProfileCategory.Connected,
					ProfileCategory.Requested,
				],
				props: (cat) => ({
					id: cat,
					label: cat,
				}),
			}}
			title="Mentors"
			loading={loading || relationshipsLoading}
			error={error || relationshipsError}
		>
			<Box p={1} />
			<Grid container spacing={4} alignItems={'stretch'}>
				{mentors.map((mentor) => (
					<MentorCard
						onConnect={() => {
							setFocused(mentor);
						}}
						relationships={relationships}
						key={`mentor_${mentor.uid}`}
					>
						{mentor}
					</MentorCard>
				))}
			</Grid>
			{focused && (
				<RelationshipForm
					profile={focused}
					relationships={relationships}
					onClose={() => {
						setFocused(null);
					}}
				/>
			)}
		</AppNav>
	);
};

export default MentorList;
