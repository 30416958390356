import { gql } from '@apollo/client';
import { Education } from '../Education/Education';
import { OrganizationRole } from '../OrganizationRole/OrganizationRole';
import { ProfileType } from '../ProfileType/ProfileType';

export class ProfileDetail {
	uid!: string;
	name!: string;
	tag!: string;
	firstName!: string;
	lastName!: string;
	photoURL?: string | null;
	email!: string;
	bio!: string;
	dateCreated!: number;
	type!: ProfileType;
	education!: Education[];
	organizationRoles!: OrganizationRole[];
}

export const ProfileDetailFragment = gql`
	fragment ProfileDetailFragment on ProfileDetail {
		uid
		name
		tag
		firstName
		lastName
		photoURL
		email
		bio
		dateCreated
		type
		education {
			school
			degree
			year
		}
		organizationRoles {
			role
			organization {
				_id
				name
			}
			dateStarted
			dateEnded
		}
	}
`;
