import { gql } from '@apollo/client';
import { Booking, BookingFragment } from './Booking';
import { getMutationHook, getQueryHook } from '../hooks';
import { BookingFilter } from './BookingFilter';
import { BookingCreate } from './BookingCreate';
import { BookingUpdate } from './BookingUpdate';
import { ProfileDetailFragment } from '../ProfileDetail/ProfileDetail';
import { ResponseFragment } from '../Response/Response';

export const BookingQuery = gql`
	${BookingFragment}
	${ProfileDetailFragment}
	${ResponseFragment}
	query Booking($id: ObjectId!) {
		Booking(id: $id) {
			...BookingFragment
		}
	}
`;

export const BookingListQuery = gql`
	${BookingFragment}
	${ProfileDetailFragment}
	${ResponseFragment}
	query BookingList($filter: BookingFilter!) {
		BookingList(filter: $filter) {
			...BookingFragment
		}
	}
`;

export const BookingCreateMutation = gql`
	${BookingFragment}
	${ProfileDetailFragment}
	${ResponseFragment}
	mutation Mutation($data: BookingCreate!) {
		BookingCreate(data: $data) {
			...BookingFragment
		}
	}
`;

export const BookingUpdateMutation = gql`
	${BookingFragment}
	${ProfileDetailFragment}
	${ResponseFragment}
	mutation BookingUpdate($data: BookingUpdate!, $id: ObjectId!) {
		BookingUpdate(data: $data, id: $id) {
			...BookingFragment
		}
	}
`;

export const bookingSchema = {
	get: getQueryHook<
		{ Booking: Booking },
		{
			id: string;
		}
	>(BookingQuery),
	list: getQueryHook<
		{ BookingList: Booking[] },
		{
			filter: BookingFilter;
		}
	>(BookingListQuery),
	create: getMutationHook<
		{ BookingCreate: Booking },
		{
			data: BookingCreate;
		}
	>(BookingCreateMutation),
	update: getMutationHook<
		{ BookingUpdate: Booking },
		{
			data: BookingUpdate;
			id: string;
		}
	>(BookingUpdateMutation),
};
