import React, { FC, ReactElement } from 'react';
import { useAuthContext } from '../../providers/AuthProvider';
import { Navigate, useLocation } from 'react-router-dom';
import Page from '../../components/layout/Page';
import Dashboard from '../../components/layout/Dashboard';
import Login from '../../scenes/Login';
import Signup from '../../scenes/Signup';
import { useClientContext } from '../../providers/ClientContextProvider';

export type RouteType =
	| 'public'
	| 'private'
	| 'authenticated'
	| 'admin'
	| 'anonymous';

export interface RouteElementProps {
	type: RouteType;
	element: ReactElement;
}

const RouteElement: FC<RouteElementProps> = ({ type, element }) => {
	const { clientContext } = useClientContext();
	const { user, initiated } = useAuthContext();
	const { pathname } = useLocation();

	const pathLoaders: Record<string, ReactElement> = {
		app: <Dashboard />,
		login: <Login />,
		signup: <Signup />,
	};

	const keyMatch = Object.keys(pathLoaders).find((key) =>
		pathname.includes(key)
	);

	const loadingElement = keyMatch ? pathLoaders[keyMatch] : <Page loading />;

	const handlers: Record<RouteType, () => ReactElement> = {
		public: () => element,
		authenticated: () => {
			if (!initiated) return loadingElement;
			else if (!user)
				return <Navigate to="/login" state={{ from: pathname }} />;
			else return element;
		},
		private: () => {
			if (!initiated) return loadingElement;
			else if (!user)
				return <Navigate to="/login" state={{ from: pathname }} />;
			else if (clientContext && !clientContext.profileDetail)
				return <Navigate to="/setup" />;
			else return element;
		},
		admin: () => {
			if (!initiated) return loadingElement;
			else if (!user)
				return <Navigate to="/login" state={{ from: pathname }} />;
			else if (clientContext && !clientContext.profileDetail)
				return <Navigate to="/setup" />;
			else return element;
		},
		anonymous: () => {
			if (!initiated) return loadingElement;
			else if (user) return <Navigate to="/app" />;
			else return element;
		},
	};

	return handlers[type]();
};

export default RouteElement;
