import React, { useState, useEffect, PropsWithChildren } from 'react';

interface CollapseProps {
	open: boolean;
	orientation?: 'vertical' | 'horizontal';
	duration?: number;
}

const Collapse: React.FC<PropsWithChildren<CollapseProps>> = ({
	open,
	orientation = 'vertical',
	children,
	duration = 300,
}) => {
	const [isContentVisible, setIsContentVisible] = useState(open);
	const [containerStyle, setContainerStyle] = useState<{
		[key: string]: string;
	}>({ transition: `all ${duration}ms ease-in-out` });

	useEffect(() => {
		if (open) {
			setIsContentVisible(true);
			setContainerStyle({});
		} else {
			if (orientation === 'vertical') {
				setContainerStyle({ height: '0', overflow: 'hidden' });
			} else {
				setContainerStyle({ width: '0', overflow: 'hidden' });
			}
		}
	}, [open, orientation, duration]);

	return <div style={containerStyle}>{isContentVisible && children}</div>;
};

export default Collapse;
