import React, { useState, useEffect } from 'react';
import { useToast } from '../../../providers/ToastProvider';
import { Box } from '@mui/joy';
import ToastBar from '../ToastBar';
import './toast.css';

export interface ToastBarContainerProps {
	anchor: 'top' | 'bottom';
}

const ToastBarContainer: React.FC<ToastBarContainerProps> = ({ anchor }) => {
	const { queue } = useToast();
	const [visibleToasts, setVisibleToasts] = useState(queue);

	useEffect(() => {
		setVisibleToasts(queue);
	}, [queue]);

	const handleAnimationEnd = (toastId: number) => {
		setVisibleToasts((currentToasts) =>
			currentToasts.filter((toast) => toast.timestamp !== toastId)
		);
	};

	return (
		<Box sx={{ p: 2 }}>
			{visibleToasts.map((toast) => (
				<div
					key={'toast_' + toast.timestamp.toString()}
					className={`toast ${anchor}-toast`}
					onAnimationEnd={() => handleAnimationEnd(toast.timestamp)}
				>
					<ToastBar {...toast} />
				</div>
			))}
		</Box>
	);
};

export default ToastBarContainer;
