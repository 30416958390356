import React, { FC, PropsWithChildren } from 'react';
import { AppRouteConfig } from '../../../../../../../scenes/App';
import { Box } from '@mui/joy';
import Nav from './components/Nav';

export interface NavSectionProps {
	routes: Record<string, AppRouteConfig>;
	open?: boolean;
}

const NavSection: FC<PropsWithChildren<NavSectionProps>> = ({
	children,
	routes,
	open = true,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				gap: 1,
				flexFlow: 'column',
			}}
		>
			{Object.entries(routes).map(([key, route]) => (
				<Nav open={open} key={`nav:${key}`}>
					{route}
				</Nav>
			))}
			{children}
		</Box>
	);
};

export default NavSection;
