import React, { FC, ReactNode } from 'react';
import { ProfileDetail } from '../../../../../../schema/ProfileDetail/ProfileDetail';
import { Box, Button, Skeleton, Typography, useTheme } from '@mui/joy';
import { MdChevronLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export interface ProfileHeaderProps {
	profile: ProfileDetail | null;
	back?: { path: string; label: string };
}

const ProfileHeader: FC<ProfileHeaderProps> = ({ profile, back }) => {
	const { palette } = useTheme();

	const nav = useNavigate();

	const size = 210;

	return (
		<Box sx={{ height: `${size}px` }}>
			<Box sx={{ height: '50%', background: palette.background.level1 }}>
				{back && (
					<Box p={1}>
						<Button
							onClick={() => nav(back.path)}
							variant="plain"
							color="neutral"
							size="sm"
							startDecorator={<MdChevronLeft />}
						>
							{back.label}
						</Button>
					</Box>
				)}
			</Box>
			<Box sx={{ height: '50%', position: 'relative' }}>
				<Box
					sx={{
						position: 'absolute',
						bottom: '12px',
						left: 0,
						paddingLeft: '24px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'flex-end',
							gap: 2,
						}}
					>
						<Box
							sx={{
								height: '120px',
								width: '120px',
								borderRadius: '50%',
								background: palette.background.level2,
								backgroundImage: `url(${profile?.photoURL})`,
								objectFit: 'cover',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
							}}
						/>
						<Box
							sx={{
								height: `${size / 2}px`,
								display: 'flex',
								flexFlow: 'column',
								justifyContent: 'center',
							}}
						>
							<Box p={1} />
							{!profile ? (
								<Typography level="h4">
									<Skeleton>Morgan Doane</Skeleton>
								</Typography>
							) : (
								<Typography level="h4">{profile.name}</Typography>
							)}
							{!profile ? (
								<Typography>
									<Skeleton>Tagline Tagline Tagline</Skeleton>
								</Typography>
							) : (
								<Typography>{profile.tag}</Typography>
							)}
							<Box p={1} />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default ProfileHeader;
