import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyD39H0I_Y6r7x4QK-UHz02atVTgVnKSx5s',
	authDomain: 'mentor-402322.firebaseapp.com',
	projectId: 'mentor-402322',
	storageBucket: 'mentor-402322.appspot.com',
	messagingSenderId: '143439992915',
	appId: '1:143439992915:web:308fb57ed4943a8bf58ea6',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
