import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import Page from '../Page';
import { Box, LinearProgress } from '@mui/joy';
import { useScreenSize } from '../../../hooks/useScreenSize';
import Topbar from './components/Topbar';
import Sidebar from './components/Sidebar';
import ToastBarContainer from '../../display/ToastBarContainer';
import Positioned from '../Positioned';

const PositionedToast = Positioned(ToastBarContainer);

export interface DashboardProps {
	loading?: boolean;
	error?: Error | null;
}

const Dashboard: FC<PropsWithChildren<DashboardProps>> = ({
	children,
	loading = false,
	error = null,
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const { sizeIs } = useScreenSize();

	const small = sizeIs('phone');

	const fromStorage = localStorage.getItem('sidebarOpen');

	const [open, setOpen] = React.useState(
		fromStorage === 'false' ? false : true
	);

	useEffect(() => {
		localStorage.setItem('sidebarOpen', open ? 'true' : 'false');
	}, [open]);

	return (
		<Page>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					flexFlow: 'column',
				}}
			>
				<Topbar open={open} setOpen={setOpen} />
				<Box
					sx={{
						flex: 1,
						display: 'flex',
						alignItems: 'stretch',
						overflow: 'hidden',
					}}
				>
					<PositionedToast anchor="bottom" corner="bottom-right" />
					{!small && <Sidebar open={open} />}
					<Box
						ref={ref}
						sx={{
							flex: 1,
							overflow: 'hidden',
							position: 'relative',
						}}
					>
						{loading && (
							<Box
								sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									right: 0,
								}}
							>
								<LinearProgress color="neutral" size="sm" />
							</Box>
						)}
						{children}
					</Box>
				</Box>
			</Box>
		</Page>
	);
};

export default Dashboard;
