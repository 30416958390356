import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	GoogleAuthProvider,
	OAuthProvider,
	User,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signInWithPopup,
} from 'firebase/auth';
import { auth } from './firebase';

export type AuthResult = { ok: true } | { ok: false; error: Error };

export interface AuthHandler<Args> {
	loading: boolean;
	handler: (args: Args) => Promise<AuthResult>;
}

const emptyHandler: AuthHandler<any> = {
	loading: false,
	handler: async () => ({ ok: true }),
};

export interface AuthContextProps {
	user: User | null;
	initiated: boolean;
	login: {
		email: AuthHandler<{ email: string; password: string }>;
		google: AuthHandler<void>;
		microsoft: AuthHandler<void>;
	};
	signup: {
		email: AuthHandler<{ email: string; password: string }>;
	};
	signout: AuthHandler<void>;
}

export const AuthContext = createContext<AuthContextProps>({
	user: null,
	initiated: false,
	login: {
		email: emptyHandler,
		google: emptyHandler,
		microsoft: emptyHandler,
	},
	signup: {
		email: emptyHandler,
	},
	signout: emptyHandler,
});

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [initiated, setInitiated] = useState(false);
	const [user, setUser] = useState<User | null>(null);
	const [loading, setLoading] = useState('');

	const login: AuthContextProps['login'] = {
		email: {
			loading: loading === 'login:email',
			handler: async ({ email, password }) => {
				setLoading('login:email');
				try {
					await signInWithEmailAndPassword(auth, email, password);
					return { ok: true };
				} catch (error) {
					return { ok: false, error: error as Error };
				} finally {
					setLoading('');
				}
			},
		},
		google: {
			loading: loading === 'login:google',
			handler: async () => {
				setLoading('login:google');
				try {
					const provider = new GoogleAuthProvider();
					await signInWithPopup(auth, provider);
					return { ok: true };
				} catch (error) {
					return { ok: false, error: error as Error };
				} finally {
					setLoading('');
				}
			},
		},
		microsoft: {
			loading: loading === 'login:microsoft',
			handler: async () => {
				setLoading('login:microsoft');
				try {
					const provider = new OAuthProvider('microsoft.com');
					provider.addScope('openid');
					provider.addScope('profile');
					provider.addScope('email');
					provider.addScope('offline_access');

					provider.setCustomParameters({
						prompt: 'select_account',
					});

					await signInWithPopup(auth, provider);
					return { ok: true };
				} catch (error) {
					return { ok: false, error: error as Error };
				} finally {
					setLoading('');
				}
			},
		},
	};

	const signup: AuthContextProps['signup'] = {
		email: {
			loading: loading === 'signup:email',
			handler: async ({ email, password }) => {
				setLoading('signup:email');
				try {
					await createUserWithEmailAndPassword(auth, email, password);
					return { ok: true };
				} catch (error) {
					return { ok: false, error: error as Error };
				} finally {
					setLoading('');
				}
			},
		},
	};

	const signout: AuthContextProps['signout'] = {
		loading: loading === 'signout',
		handler: async () => {
			setLoading('signout');
			try {
				await auth.signOut();
				return { ok: true };
			} catch (error) {
				return { ok: false, error: error as Error };
			} finally {
				setLoading('');
			}
		},
	};

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setInitiated(true);
			setUser(user);
		});
		return unsubscribe;
	}, []);

	return (
		<AuthContext.Provider value={{ user, login, signup, signout, initiated }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;

export const useAuthContext = () => useContext(AuthContext);
