import React, { useState } from 'react';

import { Box } from '@mui/joy';
import { schema } from '../../../../../../schema';
import { RelationshipFilter } from '../../../../../../schema/Relationship/RelationshipFilter';
import AppNav from '../../../../components/AppNav';
import RequestCard from './components/RequestCard';

const RequestList: React.FC = () => {
	const [filter, setFilter] = useState<RelationshipFilter>({
		hasResponded: false,
	});

	const { data, error, loading } = schema.relationship.list({
		variables: {
			filter,
		},
	});

	const relationships = data?.RelationshipList || [];

	return (
		<AppNav error={error} loading={loading} title="Mentorship Requests">
			<Box p={1} />
			<Box sx={{ display: 'flex', flexFlow: 'column', gap: 2 }}>
				{relationships.map((relationship) => {
					return (
						<RequestCard key={relationship._id}>{relationship}</RequestCard>
					);
				})}
			</Box>
		</AppNav>
	);
};

export default RequestList;
