import { gql } from '@apollo/client';
import { ProfileDetail, ProfileDetailFragment } from './ProfileDetail';
import { getMutationHook, getQueryHook } from '../hooks';
import { ProfileDetailFilter } from './ProfileDetailFilter';
import { ProfileDetailCreate } from './ProfileDetailCreate';
import { ProfileDetailUpdate } from './ProfileDetailUpdate';

export const ProfileDetailQuery = gql`
	${ProfileDetailFragment}
	query ProfileDetail($uid: String!) {
		ProfileDetail(uid: $uid) {
			...ProfileDetailFragment
		}
	}
`;

export const ProfileDetailListQuery = gql`
	${ProfileDetailFragment}
	query ProfileDetailList($filter: ProfileDetailFilter!) {
		ProfileDetailList(filter: $filter) {
			...ProfileDetailFragment
		}
	}
`;

export const ProfileDetailCreateMutation = gql`
	${ProfileDetailFragment}
	mutation ProfileDetailCreate($data: ProfileDetailCreate!) {
		ProfileDetailCreate(data: $data) {
			...ProfileDetailFragment
		}
	}
`;

export const ProfileDetailUpdateMutation = gql`
	${ProfileDetailFragment}
	mutation ProfileDetailUpdate($data: ProfileDetailUpdate!) {
		ProfileDetailUpdate(data: $data) {
			...ProfileDetailFragment
		}
	}
`;

export const profiledetailSchema = {
	get: getQueryHook<
		{ ProfileDetail: ProfileDetail },
		{
			uid: string;
		}
	>(ProfileDetailQuery),
	list: getQueryHook<
		{ ProfileDetailList: ProfileDetail[] },
		{
			filter: ProfileDetailFilter;
		}
	>(ProfileDetailListQuery),
	create: getMutationHook<
		{ ProfileDetailCreate: ProfileDetail },
		{
			data: ProfileDetailCreate;
		}
	>(ProfileDetailCreateMutation),
	update: getMutationHook<
		{ ProfileDetailUpdate: ProfileDetail },
		{
			data: ProfileDetailUpdate;
		}
	>(ProfileDetailUpdateMutation),
};
