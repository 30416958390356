import { gql } from '@apollo/client';
import { Relationship, RelationshipFragment } from './Relationship';
import { getLazyQueryHook, getMutationHook, getQueryHook } from '../hooks';
import { RelationshipFilter } from './RelationshipFilter';
import { RelationshipCreate } from './RelationshipCreate';
import { RelationshipUpdate } from './RelationshipUpdate';
import { ProfileDetailFragment } from '../ProfileDetail/ProfileDetail';
import { ResponseFragment } from '../Response/Response';

export const RelationshipQuery = gql`
	${RelationshipFragment}
	${ProfileDetailFragment}
	${ResponseFragment}
	query Relationship($id: ObjectId!) {
		Relationship(id: $id) {
			...RelationshipFragment
		}
	}
`;

export const RelationshipListQuery = gql`
	${RelationshipFragment}
	${ProfileDetailFragment}
	${ResponseFragment}
	query RelationshipList($filter: RelationshipFilter!) {
		RelationshipList(filter: $filter) {
			...RelationshipFragment
		}
	}
`;

export const RelationshipCreateMutation = gql`
	${RelationshipFragment}
	${ProfileDetailFragment}
	${ResponseFragment}
	mutation Mutation($data: RelationshipCreate!) {
		RelationshipCreate(data: $data) {
			...RelationshipFragment
		}
	}
`;

export const RelationshipUpdateMutation = gql`
	${RelationshipFragment}
	${ProfileDetailFragment}
	${ResponseFragment}
	mutation RelationshipUpdate($data: RelationshipUpdate!, $id: ObjectId!) {
		RelationshipUpdate(data: $data, id: $id) {
			...RelationshipFragment
		}
	}
`;

export const relationshipSchema = {
	get: getQueryHook<
		{ Relationship: Relationship },
		{
			id: string;
		}
	>(RelationshipQuery),
	getLazy: getLazyQueryHook<
		{ Relationship: Relationship },
		{
			id: string;
		}
	>(RelationshipQuery),
	list: getQueryHook<
		{ RelationshipList: Relationship[] },
		{
			filter: RelationshipFilter;
		}
	>(RelationshipListQuery),
	listLazy: getLazyQueryHook<
		{ RelationshipList: Relationship[] },
		{
			filter: RelationshipFilter;
		}
	>(RelationshipListQuery),
	create: getMutationHook<
		{ RelationshipCreate: Relationship },
		{
			data: RelationshipCreate;
		}
	>(RelationshipCreateMutation),
	update: getMutationHook<
		{ RelationshipUpdate: Relationship },
		{
			data: RelationshipUpdate;
			id: string;
		}
	>(RelationshipUpdateMutation),
};
