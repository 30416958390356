import React, { FC, PropsWithChildren } from 'react';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { Drawer, Modal, ModalDialog } from '@mui/joy';

export interface ResponsiveDialogProps {
	open: boolean;
	onClose: () => void;
}

const ResponsiveDialog: FC<PropsWithChildren<ResponsiveDialogProps>> = ({
	open,
	onClose,
	children,
}) => {
	const { sizeIs } = useScreenSize();

	if (sizeIs('phone'))
		return (
			<Drawer anchor="bottom" open={open} onClose={onClose}>
				{children}
			</Drawer>
		);
	else
		return (
			<Modal open={open} onClose={onClose}>
				<ModalDialog>{children}</ModalDialog>
			</Modal>
		);
};

export default ResponsiveDialog;
