import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { ToastBarProps } from '../../components/display/ToastBar';

export interface ToastContextProps {
	queue: ToastBarProps[];
	enqueue: (toast: {
		variant: ToastBarProps['variant'];
		message: ToastBarProps['message'];
		duration?: ToastBarProps['duration'];
		action?: ToastBarProps['action'];
	}) => void;
	drop: (timestamp: number) => void;
}

const Context = createContext<ToastContextProps>({
	queue: [],
	enqueue: () => {},
	drop: () => {},
});

const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [queue, setQueue] = useState<ToastBarProps[]>([]);

	useEffect(() => {
		if (queue.length === 0) return;

		const firstToast = queue[0];
		const timeLeftForFirstToast =
			firstToast.timestamp + firstToast.duration - Date.now();
		const threshold = 1500;

		// Calculate the time for the next check
		const delay = Math.max(timeLeftForFirstToast, threshold);

		const timeoutId = setTimeout(() => {
			if (Date.now() >= firstToast.timestamp + firstToast.duration) {
				setQueue((prevQueue) => prevQueue.slice(1));
			}
		}, delay);

		return () => clearTimeout(timeoutId);
	}, [queue]);

	return (
		<Context.Provider
			value={{
				queue,
				enqueue: (newToast) => {
					setQueue((prev) => [
						...prev,
						{
							...newToast,
							timestamp: Date.now(),
							duration: newToast.duration || 2500,
						},
					]);
				},
				drop: (timestamp) => {
					const newQueue = queue.filter((toast) => {
						return toast.timestamp !== timestamp;
					});
					setQueue(newQueue);
				},
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default ToastProvider;

export const useToast = () => useContext(Context);
