import React from 'react';
import { Box, Typography, useTheme } from '@mui/joy';
import Centered from '../../components/layout/Centered';

const NotFound: React.FC = () => {
	const { palette } = useTheme();
	return (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				background: palette.background.body,
			}}
		>
			<Centered>
				<Box
					sx={{
						minWidth: 240,
						display: 'flex',
						flexFlow: 'column',
						gap: 2,
						alignItems: 'center',
					}}
				>
					<Typography level="h1">Not Found</Typography>
				</Box>
			</Centered>
		</Box>
	);
};

export default NotFound;
