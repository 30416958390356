import { gql } from '@apollo/client';
import { Organization, OrganizationFragment } from './Organization';
import { getMutationHook, getQueryHook } from '../hooks';
import { OrganizationFilter } from './OrganizationFilter';
import { OrganizationCreate } from './OrganizationCreate';
import { OrganizationUpdate } from './OrganizationUpdate';

export const OrganizationQuery = gql`
	${OrganizationFragment}
	query Organization($id: ObjectId!) {
		Organization(id: $id) {
			...OrganizationFragment
		}
	}
`;

export const OrganizationListQuery = gql`
	${OrganizationFragment}
	query OrganizationList($filter: OrganizationFilter!) {
		OrganizationList(filter: $filter) {
			...OrganizationFragment
		}
	}
`;

export const OrganizationCreateMutation = gql`
	${OrganizationFragment}
	mutation Mutation($data: OrganizationCreate!) {
		OrganizationCreate(data: $data) {
			...OrganizationFragment
		}
	}
`;

export const OrganizationUpdateMutation = gql`
	${OrganizationFragment}
	mutation OrganizationUpdate($data: OrganizationUpdate!, $id: ObjectId!) {
		OrganizationUpdate(data: $data, id: $id) {
			...OrganizationFragment
		}
	}
`;

export const organizationSchema = {
	get: getQueryHook<
		{ Organization: Organization },
		{
			id: string;
		}
	>(OrganizationQuery),
	list: getQueryHook<
		{ OrganizationList: Organization[] },
		{
			filter: OrganizationFilter;
		}
	>(OrganizationListQuery),
	create: getMutationHook<
		{ OrganizationCreate: Organization },
		{
			data: OrganizationCreate;
		}
	>(OrganizationCreateMutation),
	update: getMutationHook<
		{ OrganizationUpdate: Organization },
		{
			data: OrganizationUpdate;
			id: string;
		}
	>(OrganizationUpdateMutation),
};
