import { Box, useTheme } from '@mui/joy';
import React from 'react';
import { AppRouteConfig, useAppRoutes } from '../../../../../scenes/App';
import NavSection from './components/NavSection';
import NavChip from './components/NavSection/components/Nav/components/NavChip';
import { MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export interface SidebarProps {
	open?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ open = true }) => {
	const { palette } = useTheme();
	const nav = useNavigate();

	const { routes } = useAppRoutes();

	const reduced = Object.entries(routes).reduce(
		(acc, [key, route]) => {
			if (!route.nav) return acc;
			if (route.nav.bottom) acc.bottom[key] = route;
			else acc.top[key] = route;
			return acc;
		},
		{
			top: {},
			bottom: {},
		} as Record<'top' | 'bottom', Record<string, AppRouteConfig>>
	);

	return (
		<Box
			sx={{
				p: 1,
				background: palette.background.level1,
				borderRight: `1px solid ${palette.divider}`,
				display: 'flex',
				flexFlow: 'column',
			}}
		>
			<NavSection open={open} routes={reduced.top} />
			<Box sx={{ flex: 1 }} />
			<NavSection open={open} routes={reduced.bottom}>
				<NavChip
					open={open}
					isActive={false}
					label="Logout"
					icon={<MdLogout />}
					onClick={() => {
						nav('/logout');
					}}
				/>
			</NavSection>
		</Box>
	);
};

export default Sidebar;
