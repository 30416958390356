import React, { FC, useState } from 'react';
import { ProfileDetail } from '../../../../../../../../schema/ProfileDetail/ProfileDetail';
import { Relationship } from '../../../../../../../../schema/Relationship/Relationship';
import ResponsiveDialog from '../../../../../../../../components/layout/ResponsiveDialog';
import { RelationshipUpdate } from '../../../../../../../../schema/Relationship/RelationshipUpdate';
import { RelationshipCreate } from '../../../../../../../../schema/Relationship/RelationshipCreate';
import Invite from './components/Invite';
import Manage from './components/Manage';
import { schema } from '../../../../../../../../schema';
import { useToast } from '../../../../../../../../providers/ToastProvider';
import { ProfileDetailListQuery } from '../../../../../../../../schema/ProfileDetail/operations';
import { RelationshipListQuery } from '../../../../../../../../schema/Relationship/operations';

export interface RelationshipFormProps {
	profile: ProfileDetail;
	relationships: Relationship[];
	onClose: () => void;
}

const RelationshipForm: FC<RelationshipFormProps> = ({
	profile,
	relationships,
	onClose,
}) => {
	const { enqueue } = useToast();

	const relationship =
		relationships.find(
			(relationship) => relationship.mentor.uid === profile?.uid
		) || null;

	const [state, setState] = useState<
		| {
				type: 'invite';
				data: RelationshipCreate;
		  }
		| {
				type: 'manage';
				data: RelationshipUpdate;
		  }
	>(
		relationship
			? { type: 'manage', data: {} }
			: {
					type: 'invite',
					data: {
						profile: profile?.uid,
						note: '',
					},
			  }
	);

	const [create, { loading: createLoading }] = schema.relationship.create({
		variables: state.type === 'invite' ? { data: state.data } : undefined,
		onCompleted: () => {
			enqueue({
				message: 'Request sent',
				variant: 'success',
			});
			onClose();
		},
		onError: (e) => {
			enqueue({
				message: "Couldn't send request",
				variant: 'danger',
			});
			onClose();
		},
		refetchQueries: [ProfileDetailListQuery, RelationshipListQuery],
	});

	const [update, { loading: updateLoading }] = schema.relationship.update({
		variables:
			state.type === 'manage' && relationship
				? { data: state.data, id: relationship._id }
				: undefined,
		onCompleted: () => {
			enqueue({
				message: 'Request updated',
				variant: 'success',
			});
			onClose();
		},
		onError: (e) => {
			enqueue({
				message: "Couldn't update request",
				variant: 'danger',
			});
			onClose();
		},
		refetchQueries: [ProfileDetailListQuery, RelationshipListQuery],
	});

	return (
		<ResponsiveDialog open={Boolean(profile)} onClose={onClose}>
			{state.type === 'invite' ? (
				<Invite
					loading={createLoading}
					onSubmit={() => create()}
					profile={profile}
					value={state.data}
					onChange={(value) => {
						if (!value) onClose();
						else setState({ type: 'invite', data: value });
					}}
				/>
			) : (
				<Manage
					value={state.data}
					onChange={(value) => {
						if (!value) onClose();
						else setState({ type: 'manage', data: value });
					}}
				/>
			)}
		</ResponsiveDialog>
	);
};

export default RelationshipForm;
