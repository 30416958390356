import { gql } from '@apollo/client';
import { Identified } from '../Identified/Identified';

export class Organization extends Identified {
	name!: string;
}

export const OrganizationFragment = gql`
	fragment OrganizationFragment on Organization {
		_id
		name
	}
`;
