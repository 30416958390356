import React from 'react';
import { useParams } from 'react-router-dom';
import ProfileDetail from '../../../../components/ProfileDetail';

const RequestProfile: React.FC = () => {
	const { uid } = useParams();
	return (
		<ProfileDetail
			back={{
				label: 'Requests',
				path: '/app/requests',
			}}
			uid={uid || ''}
		/>
	);
};

export default RequestProfile;
