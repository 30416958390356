import React, { FC } from 'react';
import { AppRouteConfig } from '../../../../../../../../../scenes/App';
import NavDrawerChipRender from './components/NavDrawerChipRender';
import { useLocation, useNavigate } from 'react-router-dom';

export interface NavDrawerChipProps {
	children: AppRouteConfig;
	onNav: () => void;
}

const NavDrawerChip: FC<NavDrawerChipProps> = ({ children: route, onNav }) => {
	const nav = useNavigate();
	const { pathname } = useLocation();

	if (!route.nav) return null;

	const isActive = route.index
		? pathname === '/app'
		: pathname.includes(route?.path?.replace('/*', '') || '');

	return (
		<NavDrawerChipRender
			onClick={() => {
				if (route.path) {
					nav(route.path.replace('/*', ''));
					onNav();
				} else if (route.index) {
					nav('/app');
					onNav();
				}
			}}
			icon={route.nav.icon}
			label={route.nav.label}
			isActive={isActive}
		/>
	);
};

export default NavDrawerChip;
