import React, { FC } from 'react';
import { Timing } from '../../../../../../schema/Availability/Availability';
import { Box, Grid, IconButton, Option, Select, Typography } from '@mui/joy';
import { MdAdd, MdDelete } from 'react-icons/md';

export interface DayFormProps {
	value: Timing[];
	onChange: (value: Timing[]) => void;
	label: string;
}

const DayForm: FC<DayFormProps> = ({ label, value, onChange }) => {
	const startHour = 8;
	const endHour = 18;

	const halfHours = Array.from(
		{ length: 2 * (endHour - startHour) + 1 },
		(_, i) => {
			const hour = Math.floor(i / 2) + startHour;
			const minute = i % 2 === 0 ? '00' : '30';
			const hourLabel = hour > 12 ? hour - 12 : hour;
			const ampm = hour >= 12 ? 'PM' : 'AM';
			return {
				label: `${hourLabel}:${minute} ${ampm}`,
				value: hour + parseInt(minute) / 60,
			};
		}
	);

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid xs={11}>
					<Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
				</Grid>
				<Grid xs={1}>
					<IconButton
						onClick={() => {
							onChange([...value, { start: 15, end: 17 }]);
						}}
					>
						<MdAdd />
					</IconButton>
				</Grid>
			</Grid>
			<Box p={0.5} />
			<Box>
				{value.map(({ start, end }, index) => {
					const filteredStartOptions = halfHours.filter(
						({ value }) => value < end
					);
					const filteredEndOptions = halfHours.filter(
						({ value }) => value > start
					);
					return (
						<Box key={`${label}_${index}`}>
							<Grid container spacing={2}>
								<Grid xs={6}>
									<Select
										value={start}
										onChange={(e, v) => {
											if (v) {
												onChange([
													...value.slice(0, index),
													{ start: v, end: value[index].end },
													...value.slice(index + 1),
												]);
											}
										}}
									>
										{filteredStartOptions.map(({ label, value }) => (
											<Option value={value}>{label}</Option>
										))}
									</Select>
								</Grid>
								<Grid xs={5}>
									<Select
										value={end}
										onChange={(e, v) => {
											if (v) {
												onChange([
													...value.slice(0, index),
													{ end: v, start: value[index].start },
													...value.slice(index + 1),
												]);
											}
										}}
									>
										{filteredEndOptions.map(({ label, value }) => (
											<Option value={value}>{label}</Option>
										))}
									</Select>
								</Grid>
								<Grid xs={1}>
									<IconButton
										variant="soft"
										onClick={() => {
											onChange([
												...value.slice(0, index),
												...value.slice(index + 1),
											]);
										}}
									>
										<MdDelete />
									</IconButton>
								</Grid>
							</Grid>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default DayForm;
