import React from 'react';
import RouteSection from '../../../../routing/RouteSection';
import RequestList from './components/RequestList';
import RequestProfile from './components/RequestProfile';

const Requests: React.FC = () => {
	return (
		<RouteSection>
			{{
				requestList: {
					index: true,
					type: 'private',
					element: <RequestList />,
				},
				requestProfile: {
					path: ':uid',
					type: 'private',
					element: <RequestProfile />,
				},
			}}
		</RouteSection>
	);
};

export default Requests;
