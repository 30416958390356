import { Box, Button, Divider, Typography, useTheme } from '@mui/joy';
import React, { FC, PropsWithChildren } from 'react';
import { MdCheck } from 'react-icons/md';
import Collapse from '../../../Collapse';

export interface StageProps {
	active: number;
	index: number;
	label: string;
	onClick: () => void;
	last?: boolean;
}

const Stage: FC<PropsWithChildren<StageProps>> = ({
	active,
	index,
	label,
	onClick,
	children,
	last = false,
}) => {
	const { palette } = useTheme();
	const completed = index < active;

	const open = active === index;

	return (
		<Box>
			<Box>
				<Button
					color="neutral"
					variant="plain"
					disabled={!completed}
					sx={{
						':disabled': {
							color:
								active === index ? palette.text.primary : palette.text.icon,
						},
					}}
					onClick={onClick}
					startDecorator={
						<Box
							sx={{
								height: '20px',
								width: '20px',
								borderRadius: '50%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								background: completed
									? palette.success[500]
									: palette.background.level1,
							}}
						>
							<Typography
								sx={{
									color: completed ? palette.text.primary : palette.text.icon,
								}}
								level="body-xs"
							>
								{completed ? <MdCheck /> : index + 1}
							</Typography>
						</Box>
					}
				>
					{label}
				</Button>
			</Box>

			<Box sx={{ display: 'flex', alignItems: 'stretch' }}>
				<Box sx={{ paddingLeft: '25px', minHeight: '16px' }}>
					<Box
						sx={{
							borderRight: `1px solid ${palette.divider}`,
							height: '100%',
						}}
					/>
				</Box>
				<Collapse open={open}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'stretch',
							overflow: open ? 'hidden' : 'visible', // Handle overflow here
						}}
					>
						<Box
							sx={{
								flex: 1,
								padding: 3,
							}}
						>
							{children}
						</Box>
					</Box>
				</Collapse>
			</Box>
		</Box>
	);
};

export default Stage;
