import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Grid,
	Input,
	ToggleButtonGroup,
	Tooltip,
} from '@mui/joy';
import React, { FC } from 'react';
import { ProfileDetailCreate } from '../../../../schema/ProfileDetail/ProfileDetailCreate';
import { ProfileType } from '../../../../schema/ProfileType/ProfileType';
import { MdExpandMore } from 'react-icons/md';

export interface DetailFormProps {
	value: ProfileDetailCreate;
	onChange: (value: Partial<ProfileDetailCreate>) => void;
	onNext: () => void;
}

const DetailForm: FC<DetailFormProps> = ({ value, onChange, onNext }) => {
	const getHoldup = () => {
		if (!value.firstName) return 'Please enter your first name';
		else if (!value.lastName) return 'Please enter your last name';
		else if (!value.email) return 'Please enter your email';
		else if (!value.type) return 'Please select a profile type';
		else return null;
	};

	const holdup = getHoldup();

	return (
		<Grid container spacing={3}>
			<Grid xs={12} md={6}>
				<FormControl>
					<FormLabel>First Name</FormLabel>
					<Input
						autoFocus
						fullWidth
						placeholder="First Name"
						value={value.firstName}
						onChange={(e) => {
							onChange({
								firstName: e.target.value,
							});
						}}
					/>
				</FormControl>
			</Grid>
			<Grid xs={12} md={6}>
				<FormControl>
					<FormLabel>Last Name</FormLabel>
					<Input
						fullWidth
						placeholder="Last Name"
						value={value.lastName}
						onChange={(e) => {
							onChange({
								lastName: e.target.value,
							});
						}}
					/>
				</FormControl>
			</Grid>
			<Grid xs={12}>
				<FormControl>
					<FormLabel>Email</FormLabel>
					<Input
						fullWidth
						placeholder="Email"
						value={value.email}
						onChange={(e) => {
							onChange({
								email: e.target.value,
							});
						}}
					/>
				</FormControl>
			</Grid>
			<Grid xs={12}>
				<FormControl>
					<FormLabel>Profile Type</FormLabel>
					<ToggleButtonGroup
						value={value.type}
						onChange={(e, type) => {
							if (type) {
								onChange({
									type,
								});
							}
						}}
					>
						<Button fullWidth value={ProfileType.Mentee}>
							Mentee
						</Button>
						<Button fullWidth value={ProfileType.Mentor}>
							Mentor
						</Button>
					</ToggleButtonGroup>
				</FormControl>
			</Grid>
			<Grid xs={6} sm={3}>
				<Box p={1} />
				<Tooltip arrow title={holdup || ''}>
					<Box>
						<Button
							onClick={onNext}
							disabled={Boolean(holdup)}
							endDecorator={<MdExpandMore />}
							fullWidth
						>
							Next
						</Button>
					</Box>
				</Tooltip>
			</Grid>
		</Grid>
	);
};

export default DetailForm;
