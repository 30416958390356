import { gql } from '@apollo/client';
import { Identified } from '../Identified/Identified';
import { ProfileDetail } from '../ProfileDetail/ProfileDetail';

export class Response extends Identified {
	profile!: ProfileDetail;
	date!: number;
	accepted!: boolean;
	note!: string;
}

export const ResponseFragment = gql`
	fragment ResponseFragment on Response {
		_id
		profile {
			...ProfileDetailFragment
		}
		date
		accepted
		note
	}
`;
