import React, { FC } from 'react';
import { ProfileDetail } from '../../../../../../schema/ProfileDetail/ProfileDetail';
import { Box, Skeleton, Typography } from '@mui/joy';

export interface BioProps {
	profile: ProfileDetail | null;
}

const Bio: FC<BioProps> = ({ profile }) => {
	if (!profile)
		return (
			<Box>
				<Typography>
					<Skeleton>
						Lorem ipsum is placeholder text commonly used in the graphic, print.
					</Skeleton>
				</Typography>
				<Typography>
					<Skeleton>
						Lorem ipsum is placeholder text commonly used in the graphic, print.
					</Skeleton>
				</Typography>
				<Typography>
					<Skeleton>
						Lorem ipsum is placeholder text commonly used in the graphic, print.
					</Skeleton>
				</Typography>
			</Box>
		);
	return <Typography>{profile.bio}</Typography>;
};

export default Bio;
