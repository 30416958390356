import React from 'react';
import { useParams } from 'react-router-dom';
import ProfileDetail from '../../../../components/ProfileDetail';

const MentorPage: React.FC = () => {
	const { uid } = useParams();

	return (
		<ProfileDetail
			back={{
				label: 'Mentors',
				path: '/app/mentors',
			}}
			uid={uid || ''}
		/>
	);
};

export default MentorPage;
